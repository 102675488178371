// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useRef, HTMLAttributes } from "react"
import styled from 'styled-components/macro';
import { useLanguageState } from '../../globalStates/LanguageState';
import { Message } from '../ConferenceRoom';
import { useChimeContext } from '../context/ChimeContext';
import VideoNameplate from './VideoNameplate';


const ContentVideoRoot = styled.div<{ guestBannerHeight: number }> `
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: calc(100vh - 60px - 80px - ${props => props.guestBannerHeight + "px"}); /* 60 TopBar 80 controls*/
  height: 100vh;

  video {
    max-width: 100%;
    max-height: 98%;
    width: 100%;
    height: 98%;
  }
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  guestBannerHeight: number
};

export default function ContentVideo(props: Props) {
  const chime = useChimeContext()
  const strings = useLanguageState().getStrings()
  const videoElement = useRef(null)
  const isScreenShareEnabled = chime.isScreenShareEnabled()
  const isLocalScreenShare = chime.isLocalScreenSharingStarted()
  const shareScreenTileId = chime.getShareScreenTile()?.tileId
  useEffect(() => {
    if (!shareScreenTileId)
      return
    if (isScreenShareEnabled && !isLocalScreenShare) {
      chime.bindVideoElement(
        shareScreenTileId,
        (videoElement.current as unknown) as HTMLVideoElement
      )
    } else {
      chime.unbindVideoElement(shareScreenTileId)
    }
    return () => {
      if (shareScreenTileId && isScreenShareEnabled && !isLocalScreenShare)
        chime.unbindVideoElement(shareScreenTileId)
    }
  },
    // eslint-disable-next-line
    [shareScreenTileId, isScreenShareEnabled, isLocalScreenShare]);
  let attendeeId = chime.getShareScreenTile()?.boundAttendeeId
  if (attendeeId) {
    attendeeId = attendeeId.substr(0, attendeeId.indexOf("#"))
  }

  if (isScreenShareEnabled)
    return (
      <ContentVideoRoot guestBannerHeight={props.guestBannerHeight} className={props.className}>
        {isLocalScreenShare ?
          <Message>{strings.conferenceTexts.currentlySharingScreen}</Message>
          : <>
            <video muted ref={videoElement} />
            {attendeeId && <VideoNameplate attendeeId={attendeeId} />}
          </>
        }
      </ContentVideoRoot>
    )
  else return null
}
