import * as React from "react"
import styled from "styled-components"
import { BasisPremiumConfig, BasisPremiumType, TileSize } from "../../branding/BasisPremiumBranding"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import branding from "../../branding/branding"
import EntityAction from "./EntityAction"
import { Entity, ShareTargetType } from "../../backendServices/Types"
import { useLanguageState } from "../../globalStates/LanguageState"
import { trackVisit } from "../../backendServices/BackendServices"
import { hasAccessToOrganization } from "../../utils/Authorization"
import { isExplorationOrPostEventPhase } from "../../utils/EventPhaseChecker"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import { getActionsConfig, ActionType, LayoutType } from "./actionsUtils"
import { CalendarEntryModalViewMode, useCalendarEntryModal } from "../../ui/CalendarEntryModal"
import { useGuestModal } from "../../ui/GuestModal"
import { usePrivacyPolicyModal } from "../../ui/PrivacyPolicyModal"
import { useRecommendModal } from "../../ui/RecommendModal"
import { useSayHelloModal } from "../../ui/SayHelloModal"
import { useFavoriteState } from "../../globalStates/Favorites"

const ActionsRoot = styled.div<{ width?: string; tileSize?: TileSize; layout?: LayoutType }>`
    width: ${(props) => props.width};
    display: ${(props) => (props.layout === "lobby-layout" ? "flex" : "")};
    justify-content: ${(props) => (props.layout === "lobby-layout" ? "flex-end" : "")};

    @media (max-width: 2000px) {
        width: ${(props) => (props.tileSize === TileSize.SMALL ? "80px" : props.width)};
    }

    .tile-layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .lobby-layout {
        display: flex;
        padding: 0 10px;
    }

    .list-layout {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        align-self: center;
    }
`

interface ActionsWrapperProps {
    layout: LayoutType
    organization: SimpleOrganization | undefined
    entity: Entity
    basisPremiumType: BasisPremiumType | undefined
    exhibitorHasContacts: boolean | undefined
    basisPremiumConfig?: BasisPremiumConfig
    disableFavorites?: boolean
    setAction?(value: ActionType | undefined): void
    setShowPrivacyPolicyModal?(value: boolean): void
    setShowGuestModal?(value: boolean): void
    setLink(value: string): void
    setShowRecommendOrganizationModal?(value: boolean): void
    setType?(value: ShareTargetType): void
    setTarget?(value: SimpleOrganization): void

    hideActions?: boolean
    customBookmarkFunction?: (id: string) => void
}

const ActionsWrapper: React.FC<ActionsWrapperProps> = (props) => {
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const strings = useLanguageState().getStrings()
    const favorites = useFavoriteState()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const { showRecommendModal, RecommendModal } = useRecommendModal()
    const { showSayHelloModal, SayHelloModal } = useSayHelloModal()
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal()
    const { showGuestModal, GuestModal } = useGuestModal()

    const strokeColor =
        props.basisPremiumConfig && props.basisPremiumConfig.tileBackgroundImageVisible
            ? "white"
            : branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"
    const width = props.basisPremiumConfig && props.basisPremiumConfig.tileSize === TileSize.SMALL ? "100%" : "160px"

    const isBookmarked = favorites.is(props.entity.entityType, props.entity.id)

    // getting config for actions
    const config = getActionsConfig(
        strings,
        props.layout,
        props.entity.entityType,
        props.exhibitorHasContacts,
        props.disableFavorites,
        props.basisPremiumType,
        strokeColor,
        strokeColor,
        isBookmarked
    )

    const onAction = (action: ActionType, organization: SimpleOrganization | undefined) => {
        if (loggedInUserId) {
            // recommend action for joboffer and organization
            if (action === "RECOMMEND") {
                trackVisit(loggedInUserId, props.entity.id, "RECOMMENDATION#CLICK")
                showRecommendModal(
                    props.entity.entityType === "joboffer" ? ShareTargetType.JOBOFFER : ShareTargetType.ORGANIZATION,
                    props.entity.id,
                    buildDetailLink(props.entity.id, props.entity.name ?? "", props.entity.entityType)
                )
            }

            if (organization) {
                if (!hasAccessToOrganization(userLink.user()!, organization.id)) {
                    showGuestModal() // TODO -> check if it exists
                    return
                }
                switch (action) {
                    case "EXPO":
                        if (!isExplorationOrPostEventPhase) {
                            trackVisit(loggedInUserId, organization.id, "EXPO")
                            if (props.setAction) props.setAction(action)
                            if (props.setTarget) props.setTarget(organization)
                            if (props.setShowPrivacyPolicyModal) props.setShowPrivacyPolicyModal(true)
                        }
                        break
                    case "MEETING":
                        trackVisit(loggedInUserId, organization.id, "CALENDARENTRY#CLICK")
                        showPrivacyPolicyModal(organization, () => {
                            showCalendarEntryModal({
                                organizationId: organization.id,
                                organizationName: organization.name,
                                viewMode: CalendarEntryModalViewMode.CREATE
                            })
                        })
                        break
                    case "INTEREST":
                        trackVisit(loggedInUserId, organization.id, "INTEREST#CLICK")
                        showPrivacyPolicyModal(organization, () => {
                            showSayHelloModal({ organizationModal: true, targetId: organization.id, organizationName: organization.name })
                        })
                        break
                }
            }
        }
    }

    return (
        <>
            <ActionsRoot width={width} tileSize={props.basisPremiumConfig?.tileSize} layout={props.layout}>
                <div className={props.layout}>
                    {config.map((item, index) => {
                        return (
                            item.displayCondition && (
                                <EntityAction
                                    key={index}
                                    layout={props.layout}
                                    tooltip={item.tooltip}
                                    action={item.actionType}
                                    icon={item.icon && item.icon[props.layout]}
                                    entity={props.entity}
                                    organization={props.organization}
                                    onAction={onAction}
                                    basisPremiumConfig={props.basisPremiumConfig}
                                    strokeColor={item.strokeColor}
                                    hideAction={
                                        (props.hideActions && item.actionType !== "BOOKMARK") || item.actionType === "EXPO"
                                    }
                                    customBookmarkFunction={props.customBookmarkFunction}
                                />
                            )
                        )
                    })}
                </div>
            </ActionsRoot>
            <GuestModal />
            <CalendarModal />
            <PrivacyModal />
            <RecommendModal />
            <SayHelloModal />
        </>
    )
}

export default ActionsWrapper
