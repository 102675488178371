import { createState, State, useState } from "@hookstate/core"
import { useChimeContext, MeetingStatusCode } from "../context/ChimeContext"
import { detect } from "detect-browser"
import DeviceType from "../types/DeviceType"
import { useEffect } from "react"
import { defaultLogger as logger } from "../../globalStates/AppState"

// used to store user's selected device in localStorage
const AudioInputStorageKey = "virtualGuide-audioInput"
const AudioOutputStorageKey = "virtualGuide-audioOutput"
const VideoInputStorageKey = "virtualGuide-videoInput"

const browserCheckResult = detect()
const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"

interface DeviceState {
    videoInputDevices: MediaDeviceInfo[]
    audioInputDevices: MediaDeviceInfo[]
    audioOutputDevices: MediaDeviceInfo[]
    currentVideoInputDevice: DeviceType | null
    currentAudioInputDevice: DeviceType | null
    currentAudioOutputDevice: DeviceType | null
}

let initialized = false
const useWrapState = (state: State<DeviceState>) => {
    const chime = useChimeContext()
    const meetingStatus = chime.getMeetingStatus().meetingStatus

    useEffect(
        () => {
            if (meetingStatus !== MeetingStatusCode.Succeeded) return
            if (state.get().currentAudioInputDevice) chime.chooseAudioInputDevice(state.get().currentAudioInputDevice!.value)
            if (state.get().currentAudioOutputDevice) chime.chooseAudioOutputDevice(state.get().currentAudioOutputDevice!.value)
            if (state.get().currentVideoInputDevice) chime.chooseVideoInputDevice(state.get().currentVideoInputDevice!.value)
        },
        // eslint-disable-next-line
        [meetingStatus]
    )

    return {
        currentAudioInputDevice: () => {
            return state.get().currentAudioInputDevice
        },
        currentAudioOutputDevice: () => {
            return state.get().currentAudioOutputDevice
        },
        currentVideoInputDevice: () => {
            return state.get().currentVideoInputDevice
        },
        audioInputDevices: () => {
            return state.get().audioInputDevices
        },
        audioOutputDevices: () => {
            return state.get().audioOutputDevices
        },
        videoInputDevices: () => {
            return state.get().videoInputDevices
        },
        setAudioInputDevice: (deviceId: string, label: string) => {
            state.set((prevState) => {
                prevState.currentAudioInputDevice = { value: deviceId, label: label }
                return prevState
            })
            localStorage.setItem(AudioInputStorageKey, deviceId)
            chime.chooseAudioInputDevice(deviceId)
        },
        setAudioOutputDevice: (deviceId: string, label: string) => {
            state.set((prevState) => {
                prevState.currentAudioOutputDevice = { value: deviceId, label: label }
                return prevState
            })
            localStorage.setItem(AudioOutputStorageKey, deviceId)
            chime.chooseAudioOutputDevice(deviceId)
        },
        setVideoInputDevice: (deviceId: string, label: string) => {
            state.set((prevState) => {
                prevState.currentVideoInputDevice = { value: deviceId, label: label }
                return prevState
            })
            localStorage.setItem(VideoInputStorageKey, deviceId)
            chime.chooseVideoInputDevice(deviceId)
        },
        ensureDevices: async (localStorageFilled?: boolean, force?: boolean) => {
            if ((initialized || localStorageFilled) && (!force || force === undefined)) {
                return
            }

            const videoInputDevices: MediaDeviceInfo[] = []
            const audioInputDevices: MediaDeviceInfo[] = []
            const audioOutputDevices: MediaDeviceInfo[] = []
            let currentVideoInputDevice: DeviceType | null = null
            let currentAudioInputDevice: DeviceType | null = null
            let currentAudioOutputDevice: DeviceType | null = null
            try {
                const astream = await navigator.mediaDevices.getUserMedia({ audio: true })
                astream.getTracks().forEach((x) => x.stop())
            } catch (e: any) {
                logError(e)
            }
            let noVideo = false
            try {
                const vstream = await navigator.mediaDevices.getUserMedia({ video: true })
                vstream.getTracks().forEach((x) => x.stop())
            } catch (e: any) {
                localStorage.removeItem("videoinputdevices")
                noVideo = true
                logError(e)
            }
            try {
                const data = await navigator.mediaDevices.enumerateDevices()
                const dataFilter = data.filter((x) => x.label !== "")
                if (dataFilter.length) {
                    videoInputDevices.push(...dataFilter.filter((x) => x.kind === "videoinput"))
                    localStorage.setItem("videoinputdevices", JSON.stringify(videoInputDevices))

                    if (!force || force === undefined) {
                        localStorage.removeItem(VideoInputStorageKey)
                    }

                    let newAudioInputDevices = []
                    newAudioInputDevices.push(...dataFilter.filter((x) => x.kind === "audioinput"))

                    if (newAudioInputDevices.length === 0) {
                        localStorage.removeItem("audioinputdevices")
                    } else {
                        audioInputDevices.push(...dataFilter.filter((x) => x.kind === "audioinput"))
                        localStorage.setItem("audioinputdevices", JSON.stringify(audioInputDevices))
                        if (!force || force === undefined) {
                            localStorage.removeItem(AudioInputStorageKey)
                        }
                    }

                    let newAudioOutputDevices = []
                    newAudioOutputDevices.push(...dataFilter.filter((x) => x.kind === "audiooutput"))

                    if (newAudioOutputDevices.length === 0) {
                        localStorage.removeItem("audiooutputdevices")
                    } else {
                        audioOutputDevices.push(...dataFilter.filter((x) => x.kind === "audiooutput"))
                        localStorage.setItem("audiooutputdevices", JSON.stringify(audioOutputDevices))

                        if (!force || force === undefined) {
                            localStorage.removeItem(AudioOutputStorageKey)
                        }
                    }
                } else {
                    localStorage.removeItem("videoinputdevices")
                    localStorage.removeItem("audioinputdevices")
                    localStorage.removeItem("audiooutputdevices")
                }
            } catch (e: any) {
                logError(e)
            }

            if (audioInputDevices.length) {
                const audioInputStorage = localStorage.getItem(AudioInputStorageKey)
                const aidIndex = audioInputDevices.findIndex((x) => x.deviceId === audioInputStorage && x.kind === "audioinput")
                const aidSelected = aidIndex === -1 ? 0 : aidIndex
                if (audioInputDevices[aidSelected])
                    currentAudioInputDevice = {
                        value: audioInputDevices[aidSelected].deviceId,
                        label: audioInputDevices[aidSelected].label
                    }
            }

            if (videoInputDevices.length && !noVideo) {
                const videoInputStorage = localStorage.getItem(VideoInputStorageKey)
                const vidIndex = videoInputDevices.findIndex((x) => x.deviceId === videoInputStorage && x.kind === "videoinput")
                const vidSelected = vidIndex === -1 ? 0 : vidIndex
                if (videoInputDevices[vidSelected])
                    currentVideoInputDevice = {
                        value: videoInputDevices[vidSelected].deviceId,
                        label: videoInputDevices[vidSelected].label
                    }
                localStorage.setItem(VideoInputStorageKey, currentVideoInputDevice?.value!)
            }

            if (!isFirefox && audioOutputDevices.length) {
                const audioOutputStorage = localStorage.getItem(AudioOutputStorageKey)
                const aodIndex = audioOutputDevices.findIndex(
                    (x) => x.deviceId === audioOutputStorage && x.kind === "audiooutput"
                )
                const aodSelected = aodIndex === -1 ? 0 : aodIndex
                if (audioOutputDevices[aodSelected])
                    currentAudioOutputDevice = {
                        value: audioOutputDevices[aodSelected].deviceId,
                        label: audioOutputDevices[aodSelected].label
                    }
            }

            if (initialized) return
            initialized = true
            state.set({
                videoInputDevices: videoInputDevices,
                audioInputDevices: audioInputDevices,
                audioOutputDevices: audioOutputDevices,
                currentVideoInputDevice: currentVideoInputDevice,
                currentAudioInputDevice: currentAudioInputDevice,
                currentAudioOutputDevice: currentAudioOutputDevice
            })
        }
    }
}
const state = createState({
    videoInputDevices: [],
    audioInputDevices: [],
    audioOutputDevices: [],
    currentVideoInputDevice: null,
    currentAudioInputDevice: null,
    currentAudioOutputDevice: null
} as DeviceState)
export const useDevices = () => useWrapState(useState(state))

function logError(error: Error) {
    // eslint-disable-next-line
    console.error(error)
    // hopefully this works enough
    logger.error({ message: "ChimeSdkWrapper " + error.name, errorMessage: error.message, errorStack: error.stack })
}
