import { EventDateBase, JobOffer, SotUser } from "./Types"
/**
 * Contains all Requests to our Backend, or to Amplify.
 *
 * Reminder: Wrap all requests in a try catch. They can fail, and the will fail, what is important is to send the error with logger.error() to aws, so we can see that something is not working.
 */
import { getProtocolAndHost, getAppUrl } from "../environments"
import {
    Company,
    Contact,
    EventDate,
    Person,
    PrivacyUserAnswer,
    Product,
    SuggestGroup,
    Trademark,
    ShareTargetType,
    News,
    VirtualCafeAccessUser,
    Category,
    Coupon,
    EntityType,
    NewsItem,
    Entity
} from "./Types"
import { getMyIdFromLocalStorage, localStorageKey } from "../globalStates/LoggedInUser"
import { API, Auth, graphqlOperation } from "aws-amplify"
import { GraphQLResult } from "@aws-amplify/api-graphql"
import { Md5 } from "ts-md5/dist/md5"
import queryString from "query-string"
import {
    CalendarEntryParticipationStatus,
    ConversationType,
    CreateCalendarEntryInput,
    CreateCalendarEntryParticipationInput,
    CreateCalendarEntryParticipationMutation,
    CreateConversationMutation,
    CreateMeetingMutation,
    CreateUserConversationMutation,
    CreateUserMutation,
    DeleteCalendarEntryMutation,
    DeleteCalendarEntryParticipationMutation,
    DeleteUserMutation,
    GetConversationByIdQuery,
    GetConversationsQuery,
    GetMeetingsQuery,
    getOnlineUsersQuery,
    GetUserQuery,
    InviteStatus,
    MessagesByConversationIdLightQuery,
    ModelCalendarEntryConditionInput,
    ModelSortDirection,
    ModelStringKeyConditionInput,
    UpdateCalendarEntryInput,
    UpdateCalendarEntryParticipationInput,
    UpdateCalendarEntryParticipationMutation,
    UpdateConversationMutation,
    UpdateMeetingParticipantMutation,
    UpdateUserMutation,
    UserActionType,
    GetConversationNameAndTypeByIdQuery,
    GetUserNameAndPictureQuery,
    GetCalendarEntryLightQuery,
    UserConversationsByUserAndConversationLightQuery,
    GetMeetingLightQuery,
    UserConversationListEntryQuery,
    GetConversationParticipationByIdQuery,
    CreateMessageLightMutation,
    NotificationDisplayGroup,
    UpdateUserConversationLightMutation,
    GetUserConversationQuery,
    GetUnreadCounterQuery,
    CreateUnreadCounterMutation,
    CreateUserSessionMutation,
    UpdateUserSessionMutation,
    usersInCallsInLoungeQuery,
    UserSessionsByLocationQuery,
    DeleteUserSessionMutation,
    NotificationsByUserIdAndDisplayGroupSortedQuery,
    BatchGetUserPresenceQuery,
    CreateConversationInput,
    CreateMessageInput,
    ModelUserActionConditionInput
} from "../API"
import { getActiveLanguage } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import {
    createUnreadCounter,
    createUserAction,
    createUserSession,
    updateUserAction,
    deleteUserSession
} from "../graphql/mutations"
import {
    createCalendarEntryLight,
    updateCalendarEntryLight,
    createMeeting,
    createMessageLight,
    createMeetingParticipant,
    deleteCalendarEntryParticipation,
    createUserConversation,
    updateUserConversationLight,
    updateMeetingParticipantLight,
    updateUserSessionLight,
    createCalendarEntryParticipationLight,
    deleteCalendarEntryLight,
    updateCalendarEntryParticipationLight,
    createConversationLight,
    createUserLight,
    deleteUserLight,
    deleteUserConversationLight,
    updateConversationLight,
    updateUserLight
} from "../graphql/ownMutations"
import {
    conversationById,
    conversationsByMembers,
    getCalendarEntryParticipations,
    getUserConversations,
    getOnlineUsers,
    getUserLight,
    listMeetings,
    messagesByConversationId,
    usersInCallsInLounge,
    conversationNameAndTypeById,
    userNameAndPicture,
    doesMeetingExist,
    getCalendarEntryLight,
    userConversationByUserAndConversation,
    listCalendarEntryByOrganization,
    getUserConversationListEntry,
    conversationParticipationIdById,
    getUserConversationLight,
    batchGetUserPresenceLight
} from "../graphql/ownQueries"
import { getUnreadCounter, userSessionsByLocation, notificationsByUserIdAndDisplayGroupSorted } from "../graphql/queries"
import FileSaver from "file-saver"
import { defaultLogger as logger } from "../globalStates/AppState"
import { getTimezoneOffest } from "../utils/DateUtils"
import moment from "moment"

export interface BackendServiceError {
    httpStatus: number
    httpStatusText: string
    responseJson: any
}

const seriesOfTopicsName = branding.configuration.sotName
const seriesOfTopicsAccessToken = branding.configuration.sotAccessToken
const topic = branding.configuration.topicName

function getDefaultParams() {
    const defaultParams = {
        topic: topic,
        os: "web",
        appUrl: getAppUrl(),
        lang: getActiveLanguage(),
        language: getActiveLanguage(),
        apiVersion: "38",
        timezoneOffset: getTimezoneOffest().toString(),
        userLang: "en-US"
    }
    switch (defaultParams.lang) {
        case "en":
            defaultParams.userLang = "en-US"
            break
        case "de":
            defaultParams.userLang = "de-DE"
            break
        default:
    }
    return defaultParams
}

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
}

interface errorDynamoDBObjectProps {
    message: string
    params: any
    error: any
}
function errorDynamoDBmessage(props: errorDynamoDBObjectProps) {
    let errorMessage: string
    let errorStack: any
    if ((props.error as DynamoDBErrors) && props.error.errors) {
        errorMessage = props.error.errors[0].errorType
        errorStack = props.error as DynamoDBErrors
    } else {
        errorMessage = props.error
        errorStack = props.error.message
    }
    logger.error({
        message: props.message,
        request: "graphql",
        params: props.params,
        errorMessage: errorMessage,
        errorStack: errorStack
    })
    // You can not process from here, please log in again.
    if (errorMessage === "No current user") {
        forceLogout("logoutReasonSessionTimeout")
    }
}

const searchCache: Map<string, any> = new Map()
export async function fetchDataWebService(path: string, params: object | null, signal?: AbortSignal, useCache?: boolean) {
    const protocolAndHost = getProtocolAndHost()

    let combinedParams = params ? { ...getDefaultParams(), ...params } : getDefaultParams()

    var formBody = new URLSearchParams()
    Object.entries(combinedParams).forEach((value) => {
        formBody.append(value[0], value[1])
    })
    const cacheKey = path + formBody.toString()
    if (useCache && searchCache.has(cacheKey)) {
        return searchCache.get(cacheKey)
    }

    const accessDataString = localStorage.getItem(localStorageKey)
    const token = accessDataString ? JSON.parse(accessDataString).jwtToken : null
    const accessHeaders = {
        beConnectionToken: token ? token : seriesOfTopicsAccessToken
    }

    defaultHeaders["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8"

    const headers = { ...accessHeaders, ...defaultHeaders }

    const req = fetch(`${protocolAndHost}/webservice${path}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        body: formBody,
        signal: signal
    })

    const resp = await req
    if (resp.status === 403) {
        forceLogout("logoutReasonForbidden")
    } else {
        if (resp.status !== 200) {
            throw Error("Could not load data.")
        }
    }
    const jsonResp = await resp.json()
    searchCache.set(cacheKey, jsonResp)
    return jsonResp
}

const createBackendErrorResponse = async (response: any) => {
    try {
        const responseJson = await response.json()
        return { httpStatus: response.status, httpStatusText: response.statusText, responseJson: responseJson }
    } catch {
        return { httpStatus: response.status, httpStatusText: response.statusText, responseJson: null }
    }
}

export async function fetchDataRest(
    path: string,
    queryParams: object | null,
    method: string = "POST",
    requestBody?: object,
    responseCallback?: Function,
    dontForceLogout?: boolean,
    customHeaders?: object
) {
    const protocolAndHost = getProtocolAndHost()
    const accessDataString = localStorage.getItem(localStorageKey)
    const token = accessDataString ? JSON.parse(accessDataString).jwtToken : null

    const accessHeaders = {
        beConnectionToken: token ? token : seriesOfTopicsAccessToken
    }

    defaultHeaders["Content-Type"] = "application/json"

    const headersMerged = { ...defaultHeaders, ...customHeaders }

    const headers = { ...accessHeaders, ...headersMerged }

    function makeParams(params: object | null): string {
        let combinedParams = getDefaultParams()
        if (params) {
            combinedParams = { ...combinedParams, ...params }
        }
        var paramsValue = new URLSearchParams()
        Object.entries(combinedParams).forEach((value) => {
            paramsValue.append(value[0], value[1])
        })
        return "?" + paramsValue
    }

    const req = fetch(protocolAndHost + `/rest${path}${makeParams(queryParams)}`, {
        method: method,
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        body: method !== "GET" && requestBody ? JSON.stringify(requestBody) : null
    })

    const resp = await req
    if (resp.status === 403) {
        if (dontForceLogout) {
            return await createBackendErrorResponse(resp)
        }

        forceLogout("logoutReasonForbidden")
    } else {
        if (responseCallback && !dontForceLogout) {
            return await responseCallback(resp)
        } else {
            if (resp.status >= 200 && resp.status < 300 && resp.status !== 204) {
                return await resp.json()
            } else {
                return await createBackendErrorResponse(resp)
            }
        }
    }
}

/*********************************************************************************************
 * EXHIBITOR LIST
 **********************************************************************************************/

export interface ExhibitorsListResponse {
    count: number
    entities: Entity[]
}

export interface ExhibitorListRequestParameter {
    numresultrows: number
    startresultrow: number
    filterlist: string[]
    order: string
    alpha?: string
    desc?: boolean
    /**
     * @title Basis premium number
     * @description Basis premium number for corresponding package
     * @title_de Basis premium number
     * @description_de Basis premium number for corresponding package
     */
    basispremium?: number
}

let cachedResults: Record<string, Promise<ExhibitorsListResponse> | ExhibitorsListResponse> = {}
const createStringKeyFromParams = (params: ExhibitorListRequestParameter) => {
    const keyList: Array<string> = [
        params.numresultrows?.toString(),
        params.startresultrow?.toString(),
        params.filterlist?.toString(),
        params.order,
        params.alpha ?? "",
        params.desc ? "desc" : "asc",
        getActiveLanguage()
    ]
    return keyList.join("_")
}

export async function loadExhibitorsData(
    params: ExhibitorListRequestParameter,
    signal?: AbortSignal,
    ignoreCache?: boolean
): Promise<ExhibitorsListResponse> {
    try {
        var key = createStringKeyFromParams(params)
        var existingResult = cachedResults[key]

        if (ignoreCache) {
            //for fetching exhibitors in lobby page
            const data = fetchDataWebService("/search", params, signal, true)
            cachedResults[key] = data
            const response = await data
            cachedResults[key] = response
            return response
        }

        if (!existingResult) {
            const data = fetchDataWebService("/search", params, signal, true)
            cachedResults[key] = data
            const response = await data
            cachedResults[key] = response
            return response
        } else {
            if (Promise.resolve(existingResult) === existingResult) return await existingResult
            else return existingResult
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, entities: [] }
    }
}

/*********************************************************************************************
 * EXHIBITOR DETAILS
 **********************************************************************************************/
export interface ExhibitorResponse {
    content: Company
}

export interface ExhibitorDetailsParams {
    organizationid: string
    hideNewsdata: boolean
    showAll?: boolean
    showPersonsEventDates?: boolean
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadExhibitorData(params: ExhibitorDetailsParams, signal?: AbortSignal): Promise<ExhibitorResponse> {
    try {
        const data = await fetchDataWebService("/companydetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/companydetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * TRADEMARK DETAILS
 **********************************************************************************************/
export interface TrademarkResponse {
    content: Trademark
}

export interface TrademarkDetailsParams {
    trademarkid: string
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadTrademarkData(params: TrademarkDetailsParams, signal?: AbortSignal): Promise<TrademarkResponse> {
    try {
        const data = await fetchDataWebService("/trademarkdetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/trademarkdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * JOBOFFER DETAILS
 **********************************************************************************************/
export interface JobOfferResponse {
    content: JobOffer
}

export interface JobOfferDetailParams {
    id: string
}

export async function loadJobOfferData(params: JobOfferDetailParams, signal?: AbortSignal): Promise<JobOfferResponse> {
    try {
        const data = await fetchDataWebService("/jobofferdetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/jobofferdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * COUPON DETAILS
 **********************************************************************************************/
export interface CouponDetailsResponse {
    content: Coupon
}

export interface CouponDetailsParams {
    couponid: string
}

export async function loadCouponData(params: object): Promise<CouponDetailsResponse> {
    try {
        const data = await fetchDataWebService("/search", params)
        return { content: data.entities[0] }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

//TODO find out why /coupondetails endpoint is unreachable
/*
    export async function loadCouponData(
    params: CouponDetailsParams,
    signal?: AbortSignal
): Promise<CouponDetailsResponse> {
    try {
        const data = await fetchDataWebService("/coupondetails", params, signal);
        return { content: data };
    } catch (error: any) {
        logger.error({ message: "BackendServices fetch failed", request: "/coupondetails", params, errorMessage: error.message, errorStack: error.stack });
        throw error;
    }
}*/

/*********************************************************************************************
 * COUPON LIST
 **********************************************************************************************/
export interface CouponListResponse {
    count: number
    coupons: Coupon[]
}

export async function loadCouponsData(params: object, signal?: AbortSignal): Promise<CouponListResponse> {
    try {
        const data = await fetchDataWebService("/couponlist", params, signal)
        return { count: data.numForTabs.co, coupons: data.coupons }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/couponlist",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, coupons: [] }
    }
}
/*********************************************************************************************
 * EVENT DETAILS
 **********************************************************************************************/
export interface EventDetailsResponse {
    content: Event
}
export async function loadEventDetails(eventId: string, delay?: number): Promise<EventDetailsResponse | BackendServiceError> {
    const defaultRoute: string = `/eventDates/topic/${topic}/event/${eventId}`

    try {
        const data = await fetchDataRest(defaultRoute, { delayTime: delay }, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * EVENTDATE LIST
 **********************************************************************************************/
export interface EventDateListResponse {
    count?: number
    eventDates: EventDate[]
}

export async function loadEventDateList(params: object, signal?: AbortSignal): Promise<EventDateListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal)
        return { count: data.count, eventDates: transformEventDateList(data.entities) }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, eventDates: [] }
    }
}

//const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm
function transformEventDateList(eventDates: EventDate[]) {
    return eventDates.map((elem) => {
        /* if (!elem.start || !TIME_REGEX.test(elem.start)) {
            elem.start = branding.eventTiming.eventDateDefaultStartTime
        } */
        let [startHour, startMinutes] = elem?.start?.split(":")
        elem.startHour = parseInt(startHour)
        elem.startMinutes = parseInt(startMinutes)

        /* if (!elem.end || !TIME_REGEX.test(elem.end)) {
            elem.end = branding.eventTiming.eventDateDefaultEndTime
        } */
        let [endHour, endMinutes] = elem?.end?.split(":")
        elem.endHour = parseInt(endHour)
        elem.endMinutes = parseInt(endMinutes)
        return elem
    })
}

export async function loadRoundTableList(
    params: object,
    profileId: string,
    signal?: AbortSignal
): Promise<EventDateListResponse> {
    try {
        const data = await fetchDataRest(`/eventDates/topic/${topic}/profile/${profileId}/roundtables`, params, "GET")
        return { count: data.count, eventDates: transformEventDateList(data.eventDates) }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: `/eventDates/topic/${topic}/profile/${profileId}/roundtables`,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, eventDates: [] }
    }
}

/*********************************************************************************************
 * EVENTDATE DETAILS
 **********************************************************************************************/
export interface EventDateDetailsResponse {
    content: EventDate
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadEventDateDetails(params: object, signal?: AbortSignal): Promise<EventDateDetailsResponse> {
    try {
        const data = await fetchDataWebService("/eventdatedetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/eventdatedetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

export interface EventDateChannelFirstDetailsResponse {
    currentEventDate?: EventDate
    nextEventDate?: EventDate
}

export async function loadChannelFirstEventDate(
    channel: string,
    delay?: number
): Promise<EventDateChannelFirstDetailsResponse | BackendServiceError> {
    const defaultRoute: string = `/eventDates/topic/${topic}/channel/${channel}`
    try {
        const data = await fetchDataRest(defaultRoute, { delayTime: delay }, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { currentEventDate: data.currentEventDate, nextEventDate: data.nextEventDate }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}
/*********************************************************************************************
 * EVENTDATE DAYS
 **********************************************************************************************/
export interface EventDateDatesResponse {
    dates: string[]
}

export async function loadEventDateDates(params: object, signal?: AbortSignal): Promise<EventDateDatesResponse> {
    try {
        const data = await fetchDataWebService("/eventdatedates", params, signal)
        return { dates: data.dates }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/eventdatedates",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * CONTACTS LIST
 **********************************************************************************************/
export interface ContactItem {
    group: number
    sotUser: any
    orgaConnectionRequest?: any
    isBookmarked: boolean
}

export interface ContactListResponse {
    contacts: ContactItem[]
    hasNextPage: boolean
    searchString?: string
    extraItems?: any[]
    unViewedConnections?: number
}

export async function loadContactListData(
    profileId: string,
    params: { searchString: string; itemsPerPage: number; page: number; ddbCounter?: number }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { hasNextPage: data.hasNextPage, contacts: data.items, unViewedConnections: data.totalUnviewedConnections }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * EXPORT CONTACTS AS CSV
 **********************************************************************************************/

export async function exportContactsAsCsv(
    profileId: string,
    params: { forceCollaborationFeatureActive?: boolean }
): Promise<string> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts/csv`
    const customHeaders = {
        Accept: "text/csv; charset=utf-8",
        "Content-Type": "text/csv; charset=utf-8"
    }

    try {
        const result = await fetchDataRest(
            defaultRoute,
            params,
            "GET",
            undefined,
            (resp: Response) => saveFile(resp, "contacts.csv"),
            undefined,
            customHeaders
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "ERROR"
    }
}

/*********************************************************************************************
 * EXPORT CONTACTS AS VCARD
 **********************************************************************************************/

export async function exportContactsAsVCard(
    profileId: string,
    params: { forceCollaborationFeatureActive?: boolean }
): Promise<string> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts/vCard`
    const customHeaders = {
        Accept: "text/x-vcard;charset=utf-8",
        "Content-Type": "text/x-vcard;charset=utf-8"
    }

    try {
        const result = await fetchDataRest(
            defaultRoute,
            params,
            "GET",
            undefined,
            (resp: Response) => saveFile(resp, "contacts.vcf"),
            undefined,
            customHeaders
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "ERROR"
    }
}

/*********************************************************************************************
 * RELEVANT PROFILES LIST
 **********************************************************************************************/
export async function loadRelevantProfilesListData(
    profileId: string,
    params: {
        searchString: string
        itemsPerPage: number
        page: number
        positionFilter?: string
        userTypeFilter?: string
        topicFilter?: number
        hideConnections?: boolean
    }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/relevant`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { hasNextPage: data.hasNextPage, contacts: data.items }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * CONTACTS REQUEST LIST
 **********************************************************************************************/
export async function loadContactRequestListData(
    profileId: string,
    params: { searchString: string; itemsPerPage: number; page: number; ddbCounter?: number }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/requests`
    try {
        // No logedUser at the moment. NEED user alias to implement in route
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return {
                hasNextPage: data.hasNextPage,
                contacts: data.items,
                extraItems: data.extraItems,
                unViewedConnections: data.totalUnviewedConnections
            }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * ORRGANIZATION CONNECT REQUESTS LIST
 **********************************************************************************************/
export async function getOrganizationReqList(profileId: string, organizationId: string): Promise<Array<any> | null> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/organization/${organizationId}/getorgaincomingreq`
    const data = await fetchDataRest(defaultRoute, {}, "GET")
    if (data.length) {
        return data
    } else {
        return null
    }
}

/*********************************************************************************************
 * CONTACTS BLOCKED LIST
 **********************************************************************************************/
export async function loadContactBlockedtListData(
    profileId: string,
    params: { searchString: string; itemsPerPage: number; page: number }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/blocks`
    try {
        // No logedUser at the moment. NEED user alias to implement in route
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { hasNextPage: data.hasNextPage, contacts: data.items, extraItems: data.extraItems }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * ALL CONVERSATIONS LIST
 **********************************************************************************************/
export interface ConversationListEntry {
    id: string
    type: ConversationType
    userConversationId: string
    isMuted: boolean
    title?: string
    opponentIds: string[]
    pictureUrls: (string | undefined)[]
    opponentNames: string[]
    lastMessage?: string
    lastMessageTime: Date
    lastReadTime?: Date | undefined
}

export async function loadConversationListEntries(
    profileId: string,
    conversationMemberLimit: number,
    nextToken?: string
): Promise<[ConversationListEntry[], string | undefined] | undefined> {
    const params = { userId: profileId, callLimit: 25, memberLimit: conversationMemberLimit, nextToken: nextToken }
    try {
        const result = (await API.graphql(graphqlOperation(getUserConversations, params))) as GraphQLResult<GetConversationsQuery>
        if (result?.data?.userConversationsByUser?.items) {
            const convos: ConversationListEntry[] = []
            const userConvos = result.data.userConversationsByUser.items
            const nextToken = result.data.userConversationsByUser.nextToken
            userConvos.forEach((item) => {
                const convEntry = processConversationListEntry(profileId, item)
                if (convEntry) {
                    convos.push(convEntry)
                }
            })
            return [convos, nextToken ?? undefined]
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUserConversations failed", params: params, error: error })
        return undefined
    }
}

export async function loadConversationListEntry(
    profileId: string,
    conversationId: string,
    conversationMemberLimit: number
): Promise<ConversationListEntry | undefined> {
    const params = { userId: profileId, conversationId: conversationId, memberLimit: conversationMemberLimit }
    try {
        const result = (await API.graphql(
            graphqlOperation(getUserConversationListEntry, params)
        )) as GraphQLResult<UserConversationListEntryQuery>
        if (result?.data?.userConversationsByUserAndConversation?.items) {
            const userConvos = result.data.userConversationsByUserAndConversation.items as ConversationListEntryProps[]
            return processConversationListEntry(profileId, userConvos[0])
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUserConversationListEntry failed", params: params, error: error })
        return undefined
    }
}

interface ConversationListEntryProps {
    __typename: "UserConversation"
    id: string
    isMuted?: boolean | null
    conversation: {
        __typename: "Conversation"
        id: string
        type?: ConversationType | null
        name?: string | null
        mostRecentMessage?: string | null
        members?: {
            __typename: "ModelUserConversationConnection"
            items?:
            | ({
                __typename: "UserConversation"
                id: string
                userId: string
                user: {
                    __typename: "User"
                    id: string
                    name?: string | null
                    pictureUrl?: string | null
                }
            } | null)[]
            | null
        } | null
    }
    mostRecentMessageCreatedAt?: string | null
    lastReadMessageCreatedAt?: string | null
}

function processConversationListEntry(
    profileId: string,
    item: ConversationListEntryProps | null
): ConversationListEntry | undefined {
    if (!item) {
        return
    }
    const convo = item.conversation
    const opponents = convo.members?.items
        ?.filter((item) => item && item.user && item?.user.id !== profileId)
        ?.map((item) => item!.user!)
    if (!opponents || (convo.type === ConversationType.PRIVATE && opponents.length === 0)) {
        return
    }
    let convoTitle: string | undefined
    if (!convo.type || convo.type === ConversationType.PRIVATE) {
        convoTitle = opponents[0].name ?? undefined
    } else {
        convoTitle = convo.name ?? undefined
    }
    const myUserConvo = item
    return {
        id: convo.id,
        type: convo.type ?? ConversationType.PRIVATE,
        userConversationId: myUserConvo.id,
        isMuted: myUserConvo.isMuted ?? false,
        title: convoTitle,
        opponentIds: opponents.map((o) => o.id),
        pictureUrls: opponents.map((o) => o.pictureUrl ?? undefined),
        opponentNames: opponents.map((o) => o.name!),
        lastMessage: convo.mostRecentMessage!,
        lastMessageTime: new Date(item.mostRecentMessageCreatedAt!),
        lastReadTime: item.lastReadMessageCreatedAt ? new Date(item.lastReadMessageCreatedAt!) : undefined
    }
}

/*********************************************************************************************
 * NOTIFICATIONS
 **********************************************************************************************/

export interface loadNotificationsProps {
    userId: string
    displayGroup: NotificationDisplayGroup
    nextToken?: string
}

export interface loadNotificationsByUserIdAndTypeResult {
    data: {
        notificationsByUserIdAndDisplayGroupSorted: {
            items: [
                {
                    userId: string
                    content: string
                    createdAt: string
                }
            ]
            nextToken: String | undefined
        }
    }
}

export async function loadNotificationsByUserIdAndDisplayGroup(
    parameters: loadNotificationsProps
): Promise<NotificationsByUserIdAndDisplayGroupSortedQuery | null> {
    const userIdDisplayGroup = parameters.userId + parameters.displayGroup
    const params = {
        userIdDisplayGroup: userIdDisplayGroup,
        sortDirection: ModelSortDirection.DESC,
        nextToken: parameters.nextToken,
        limit: 25
    }
    try {
        const findNotificationResult = (await API.graphql(
            graphqlOperation(notificationsByUserIdAndDisplayGroupSorted, params)
        )) as any
        return findNotificationResult.data as NotificationsByUserIdAndDisplayGroupSortedQuery
    } catch (error: any) {
        errorDynamoDBmessage({
            message: "BackendServices notificationsByUserIdAndDisplayGroupSorted failed",
            params: params,
            error: error
        })
        return null
    }
}

/*********************************************************************************************
 * CONTACTS CONVERSATIONS LIST
 **********************************************************************************************/
export async function loadContactsConversationsListData(
    profileId: string,
    params: object
): Promise<ContactListResponse | BackendServiceError> {
    let defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/conversations`
    let conversationsData
    try {
        conversationsData = await fetchDataRest(defaultRoute, params, "GET")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
    defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts`
    let contactsData
    try {
        contactsData = await fetchDataRest(defaultRoute, params, "GET")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }

    var data = []

    try {
        for (var i = 0; i < conversationsData.items.length; i++) {
            for (var j = 0; j < contactsData.items.length; j++) {
                if (conversationsData.items[i].sotUser.id === contactsData.items[j].sotUser.id) {
                    data.push(conversationsData.items[i])
                }
            }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices unpack conversationsData failed",
            errorMessage: error.message,
            errorStack: error.stack
        })
    }

    conversationsData.items = data

    if ((conversationsData as BackendServiceError).httpStatus) {
        return conversationsData
    } else {
        return { hasNextPage: conversationsData.hasNextPage, contacts: conversationsData.items }
    }
}

export interface ChatMessage {
    id: string
    content: string
    authorId: string
    isSent: boolean
    timestamp: Date
    conversationId: string
}

export interface ConversationEntry {
    id: string
    name?: string
    description?: string
    userConversationId: string
    isMuted: boolean
    opponents: User[]
}

/**********************************************************************************************
 * CHAT
 **********************************************************************************************/

export async function findChatConversation(
    profileId: string,
    opponentId: string
): Promise<{ conversationId: string; userConversationId: string; isMuted: boolean } | undefined> {
    const params = { memberId1: profileId, memberId2: opponentId }
    try {
        const findConversationResult = (await API.graphql(graphqlOperation(conversationsByMembers, params))) as GraphQLResult<any>
        const conversations = findConversationResult.data?.getConversationsByMembers?.items
        if (!conversations?.length) {
            return undefined
        }
        const conversation = conversations.find((c: any) => {
            const members = c?.members?.items
            if (members?.length === 2) {
                const userId1 = members[0]?.userId
                const userId2 = members[1]?.userId
                return (userId1 === profileId && userId2 === opponentId) || (userId1 === opponentId && userId2 === profileId)
            }
            return false
        })
        const userConversation = conversation?.members?.items?.find((item: any) => item.userId === profileId)

        if (!conversation || !userConversation) {
            return undefined
        }

        return {
            conversationId: conversation.id,
            userConversationId: userConversation.id,
            isMuted: userConversation.isMuted ?? false
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationByMemberIdsHash failed", params: params, error: error })
        return undefined
    }
}

export async function getConversationParticipantCount(conversationId: string): Promise<number | undefined> {
    const params = { id: conversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(conversationParticipationIdById, params)
        )) as GraphQLResult<GetConversationParticipationByIdQuery>

        return result.data?.getConversation?.members?.items?.length
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationParticipationIdById failed", params: params, error: error })
        return undefined
    }
}

export async function getConversationParticipants(conversationId: string, membersLimit?: number): Promise<User[] | undefined> {
    const params = { id: conversationId, limit: membersLimit }
    try {
        const result = (await API.graphql(graphqlOperation(conversationById, params))) as GraphQLResult<GetConversationByIdQuery>
        const conversation = result?.data?.getConversation
        if (!conversation) {
            return undefined
        }
        return conversation.members?.items?.map((item) => {
            return {
                id: item!.user.id,
                name: item!.user.name ?? "",
                pictureUrl: item!.user.pictureUrl ?? undefined
            }
        })
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationById failed", params: params, error: error })
        return undefined
    }
}

export async function checkConversationExists(
    conversationId: string,
    participantIds: string[]
): Promise<{ conversationExists: boolean; userConversationExists: boolean[] }> {
    const params = { id: conversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(conversationParticipationIdById, params)
        )) as GraphQLResult<GetConversationParticipationByIdQuery>
        const conversation = result.data?.getConversation
        const conversationParticipantIds = conversation?.members?.items?.map((uc) => uc?.userId) ?? []
        const userConversationExists = participantIds.map((id) => conversationParticipantIds.includes(id))

        return { conversationExists: !!conversation, userConversationExists: userConversationExists }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationParticipationIdById failed", params: params, error: error })
        return { conversationExists: false, userConversationExists: [] }
    }
}

export async function findChatConversationById(
    conversationId: string,
    profileId: string,
    membersLimit?: number
): Promise<ConversationEntry | undefined> {
    const params = { id: conversationId, limit: membersLimit }
    try {
        const result = (await API.graphql(graphqlOperation(conversationById, params))) as GraphQLResult<GetConversationByIdQuery>
        const conversation = result?.data?.getConversation
        if (!conversation) {
            return undefined
        }
        const opponents: User[] = []
        let userConversationId: string
        let isMuted: boolean
        conversation.members?.items?.forEach(function (item: any, index: number) {
            if (item.user.id === profileId) {
                userConversationId = item.id
                isMuted = item.isMuted
            } else {
                opponents.push(item.user)
            }
        })
        return {
            id: conversationId,
            name: conversation.name ?? undefined,
            description: conversation.description ?? undefined,
            userConversationId: userConversationId!,
            isMuted: isMuted!,
            opponents: opponents
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationById failed", params: params, error: error })
        return undefined
    }
}

export async function getLastReadUserConversation(userCoversationId: string) {
    const params = { id: userCoversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(getUserConversationLight, params)
        )) as GraphQLResult<GetUserConversationQuery>
        const userConversation = result.data?.getUserConversation

        return userConversation
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUserConversation failed", params: params, error: error })
        return undefined
    }
}

export async function createPrivateChatConversation(
    profileId: string,
    opponentId: string,
    isMuted?: boolean
): Promise<{ conversationId: string; userConversationId: string; isMuted: boolean } | undefined> {
    const participantIdsSorted = [profileId, opponentId].sort()
    // const participantIdsSorted = Object.assign([], participantIds).sort();
    const idsHash = Md5.hashStr(participantIdsSorted.join(""))

    const params: { input: CreateConversationInput } = {
        input: { userId: profileId, memberIdsHash: idsHash as string, type: ConversationType.PRIVATE }
    }
    try {
        const createConvResult = (await API.graphql(
            graphqlOperation(createConversationLight, params)
        )) as GraphQLResult<CreateConversationMutation>
        if (!createConvResult?.data?.createConversation) {
            return undefined
        }
        const conversationId = createConvResult.data.createConversation.id

        const params2 = { input: { userId: opponentId, conversationId: conversationId } }
        try {
            const results = await Promise.all([
                API.graphql(
                    graphqlOperation(createUserConversation, {
                        input: { userId: profileId, conversationId: conversationId, isMuted: isMuted }
                    })
                ) as GraphQLResult<CreateUserConversationMutation>,
                API.graphql(
                    graphqlOperation(createUserConversation, { input: { userId: opponentId, conversationId: conversationId } })
                ) as GraphQLResult<CreateUserConversationMutation>
            ])

            const userConversation = results[0]?.data?.createUserConversation

            if (!userConversation) {
                return undefined
            }

            return {
                conversationId: conversationId,
                userConversationId: userConversation.id,
                isMuted: userConversation.isMuted ?? false
            }
        } catch (error: any) {
            errorDynamoDBmessage({ message: "BackendServices createUserConversation failed", params: params2, error: error })
            return undefined
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createConversation failed", params: params, error: error })
        return undefined
    }
}

/* TODO Tasks for custom resolvers
    - On UserConversation creation: 
        - set field 'mostRecentMessageCreatedAt' to same value as 'createdAt'
        - check if there already exists a UserConversation with the same userId & conversationId
        - check if conversation participant limit has already been reached
    - On send message & change conversation name/description: Validate max length
    - On send message: check if user is part of conversation
*/
async function createChatConversation(
    conversationType: ConversationType,
    profileId: string,
    opponentIds?: string[],
    name?: string,
    description?: string,
    showInListIfEmpty?: boolean,
    isMuted?: boolean,
    conversationId?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    const params: { input: CreateConversationInput } = {
        input: {
            id: conversationId,
            userId: profileId,
            name: name ?? null,
            description: description ?? null,
            type: conversationType
        }
    }
    try {
        const createConvResult = (await API.graphql(
            graphqlOperation(createConversationLight, params)
        )) as GraphQLResult<CreateConversationMutation>
        const createdConversationId = createConvResult.data?.createConversation?.id

        if (createdConversationId) {
            const results = await Promise.all([
                addParticipantsToGroupChatConversation(createdConversationId, [profileId], showInListIfEmpty, isMuted), // <= TODO adding oneself to the conersation should be done in the resolver during the Conversation creation
                addParticipantsToGroupChatConversation(createdConversationId, opponentIds ?? [], showInListIfEmpty)
            ])

            const myUserConversationId = results.flat()[0]?.createdUserConversationId

            if (!myUserConversationId) {
                return undefined
            }

            return {
                createdConversationId: createdConversationId,
                createdUserConversationId: myUserConversationId
            }
        } else return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createConversation failed", params: params, error: error })
        return undefined
    }
}

export async function createGroupChatConversation(
    profileId: string,
    opponentIds: string[],
    name?: string,
    description?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    return await createChatConversation(ConversationType.GROUP, profileId, opponentIds, name, description, true, false)
}

export async function createCalendarEntryChatConversation(
    conversationId: string,
    profileId: string,
    name?: string,
    description?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    return await createChatConversation(
        ConversationType.CALENDARENTRY,
        profileId,
        undefined,
        name,
        description,
        false,
        false,
        conversationId
    )
}

export async function createCallChatConversation(
    conversationId: string,
    profileId: string,
    opponentIds: string[],
    name?: string,
    description?: string
): Promise<{ createdConversationId: string; createdUserConversationId: string } | undefined> {
    return await createChatConversation(
        ConversationType.CALL,
        profileId,
        opponentIds,
        name,
        description,
        false,
        false,
        conversationId
    )
}

function createSingleUserConversation(participantId: string, conversationId: string, now: string | undefined, isMuted?: boolean) {
    const params = {
        input: {
            userId: participantId,
            conversationId: conversationId,
            mostRecentMessageCreatedAt: now,
            lastReadMessageCreatedAt: now,
            isMuted: isMuted
        }
    }
    try {
        const result = API.graphql(
            graphqlOperation(createUserConversation, params)
        ) as GraphQLResult<CreateUserConversationMutation>

        return result
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserConversation failed", params: params, error: error })
        return undefined
    }
}

export async function addParticipantsToGroupChatConversation(
    conversationId: string,
    participantIds: string[],
    showInListIfEmpty?: boolean,
    isMuted?: boolean
): Promise<{ participantId: string; createdUserConversationId: string }[] | undefined> {
    const now = showInListIfEmpty ? new Date().toISOString() : undefined
    const result = await Promise.all(
        participantIds.map((participantId) => createSingleUserConversation(participantId, conversationId, now, isMuted))
    )
    if (!result || result.length === 0) {
        return undefined
    }
    return result
        .filter((result) => !!result?.data?.createUserConversation)
        .map((result) => {
            const userConversation = result!.data!.createUserConversation!
            return { participantId: userConversation.userId, createdUserConversationId: userConversation.id }
        })
}

export async function updateGroupChatConversationName(conversationId: string, name: string | null) {
    const params = { input: { id: conversationId, name: name } }
    try {
        return (
            (await API.graphql(graphqlOperation(updateConversationLight, params))) as GraphQLResult<UpdateConversationMutation>
        )?.data?.updateConversation
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateConversation failed", params: params, error: error })
        return undefined
    }
}

export async function updateGroupChatConversationDescription(conversationId: string, description?: string) {
    const params = { input: { id: conversationId, description: description } }
    try {
        return (
            (await API.graphql(graphqlOperation(updateConversationLight, params))) as GraphQLResult<UpdateConversationMutation>
        )?.data?.updateConversation
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateConversation failed", params: params, error: error })
        return undefined
    }
}

/**
 * If the UserConversation ID is already known better use deleteUserConversation(userConversationId) instead.
 * @param conversationId
 * @param participantId
 */
export async function removeParticipantsFromGroupChatConversation(
    conversationId: string,
    participantId: string
): Promise<boolean> {
    const params = { userId: participantId, conversationId: conversationId }
    try {
        const findUserConversationResult = (await API.graphql(
            graphqlOperation(userConversationByUserAndConversation, params)
        )) as GraphQLResult<UserConversationsByUserAndConversationLightQuery>
        if (!findUserConversationResult?.data?.userConversationsByUserAndConversation?.items?.length) {
            return false
        }
        const userConversationId = findUserConversationResult?.data?.userConversationsByUserAndConversation?.items[0]?.id
        if (!userConversationId) return false
        return await deleteUserConversation(userConversationId)
    } catch (error: any) {
        errorDynamoDBmessage({
            message: "BackendServices userConversationByUserAndConversation failed",
            params: params,
            error: error
        })
        return false
    }
}

export async function deleteUserConversation(userConversationId: string): Promise<boolean> {
    const params = { input: { id: userConversationId } }
    try {
        return !!(await API.graphql(graphqlOperation(deleteUserConversationLight, params)))
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteUserConversation failed", params: params, error: error })
        return false
    }
}

export async function setLastReadConversation(userConversationId: string, lastReadMessageCreatedAt: string) {
    const params = {
        input: { id: userConversationId, lastReadMessageCreatedAt: lastReadMessageCreatedAt },
        condition: { lastReadMessageCreatedAt: { ne: lastReadMessageCreatedAt } }
    }
    try {
        //eslint-disable-next-line
        ; (await API.graphql(
            graphqlOperation(updateUserConversationLight, params)
        )) as GraphQLResult<UpdateUserConversationLightMutation>
    } catch (error: any) {
        if (error.errors[0]?.errorType === "DynamoDB:ConditionalCheckFailedException")
            logger.warn({
                message: "BackendServices updateUserConversationLight failed",
                request: "graphql",
                params: params,
                errorMessage: error.errors[0]?.errorType as DynamoDBErrors,
                errorStack: error as DynamoDBErrors
            })
        else errorDynamoDBmessage({ message: "BackendServices updateUserConversationLight failed", params: params, error: error })
    }
}

export async function setMuteStatus(userConversationId: string, muted: boolean): Promise<boolean | undefined> {
    const params = { input: { id: userConversationId, isMuted: muted } }
    try {
        const result = (await API.graphql(
            graphqlOperation(updateUserConversationLight, params)
        )) as GraphQLResult<UpdateUserConversationLightMutation>
        return result?.data?.updateUserConversation?.isMuted ?? undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateUserConversationLight failed", params: params, error: error })
        return undefined
    }
}

export async function sendChatMessage(conversationId: string, authorId: string, text: string): Promise<ChatMessage | undefined> {
    const params: { input: CreateMessageInput } = {
        input: { conversationId: conversationId, authorId: authorId, content: text, sotName: topic }
    }
    try {
        const result = (await API.graphql(
            graphqlOperation(createMessageLight, params)
        )) as GraphQLResult<CreateMessageLightMutation>
        if (result?.data?.createMessage) {
            const msg = result.data.createMessage
            return {
                id: msg.id,
                content: msg.content,
                authorId: msg.authorId,
                isSent: msg.isSent ?? false,
                timestamp: new Date(msg.createdAt),
                conversationId: conversationId
            }
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createMessageLight failed", params: params, error: error })
        return undefined
    }
}

export async function sendChatMessageAndCreateConversationIfNecessary(
    profileId: string,
    opponentId: string,
    text: string
): Promise<ChatMessage | undefined> {
    const findConversationResult = await findChatConversation(profileId, opponentId)
    if (findConversationResult) {
        const { conversationId } = findConversationResult
        return await sendChatMessage(conversationId, profileId, text)
    } else {
        const createConversationResult = await createPrivateChatConversation(profileId, opponentId)
        if (createConversationResult) {
            const { conversationId } = createConversationResult
            return await sendChatMessage(conversationId, profileId, text)
        } else {
            return undefined
        }
    }
}

export async function loadChatMessages(
    conversationId: string,
    nextToken?: string
): Promise<[ChatMessage[], string | undefined] | undefined> {
    const params = { conversationId: conversationId, limit: 25, nextToken: nextToken }
    try {
        const result = (await API.graphql(
            graphqlOperation(messagesByConversationId, params)
        )) as GraphQLResult<MessagesByConversationIdLightQuery>
        if (result?.data?.messagesByConversationId?.items) {
            const chatMessages: ChatMessage[] = []
            const messages = result.data.messagesByConversationId.items
            const nextToken = result.data.messagesByConversationId.nextToken
            messages.forEach((message: any) => {
                chatMessages.push({
                    id: message.id,
                    content: message.content,
                    authorId: message.authorId,
                    isSent: message.isSent,
                    timestamp: new Date(message.createdAt),
                    conversationId: message.conversationId
                })
            })
            return [chatMessages, nextToken ?? undefined]
        }
        return undefined
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices messagesByConversationId failed", params: params, error: error })
        return undefined
    }
}

/*********************************************************************************************
 * CHAT NOTIFICATIONS
 **********************************************************************************************/
export async function fetchConversationNameAndType(
    conversationId: string
): Promise<{ convType: ConversationType; convName?: string }> {
    const params = { id: conversationId }
    try {
        const result = (await API.graphql(
            graphqlOperation(conversationNameAndTypeById, params)
        )) as GraphQLResult<GetConversationNameAndTypeByIdQuery>
        const conversation = result.data?.getConversation
        const type = conversation?.type ?? ConversationType.PRIVATE
        const name = conversation?.name ?? undefined
        return { convType: type, convName: name }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices conversationNameAndTypeById failed", params: params, error: error })
        return { convType: ConversationType.PRIVATE, convName: undefined }
    }
}

export async function fetchUserName(userId: string): Promise<{ userName?: string; userPictureUrl?: string }> {
    const params = { id: userId }
    try {
        const result = (await API.graphql(
            graphqlOperation(userNameAndPicture, params)
        )) as GraphQLResult<GetUserNameAndPictureQuery>
        const user = result.data?.getUser
        const name = user?.name ?? undefined
        const pictureUrl = user?.pictureUrl ?? undefined
        return { userName: name, userPictureUrl: pictureUrl }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userNameAndPicture failed", params: params, error: error })
        return { userName: undefined, userPictureUrl: undefined }
    }
}

/*********************************************************************************************
 * SUGGESTION REQUEST
 **********************************************************************************************/
export interface SuggestResponse {
    searchString: string
    suggestGroups: SuggestGroup[]
}

export enum GroupConfigType {
    /**
     * @title Category group config type
     * @description Type name for category group config
     * @title_de Category group config type
     * @description_de Type name for category group config
     */
    cat = "cat",
    /**
     * @title Interest group config type
     * @description Type name for interest group config
     * @title_de Interest group config type
     * @description_de Type name for interest group config
     */
    interest = "interest",
    /**
     * @title City group config type
     * @description Type name for city group config
     * @title_de City group config type
     * @description_de Type name for city group config
     */
    city = "city",
    /**
     * @title Country group config type
     * @description Type name for country group config
     * @title_de Country group config type
     * @description_de Type name for country group config
     */
    country = "country",
    /**
     * @title Entity group config type
     * @description Type name for entity group config
     * @title_de Entity group config type
     * @description_de Type name for entity group config
     */
    entity = "entity",
    /**
     * @title Hall group config type
     * @description Type name for hall group config
     * @title_de Hall group config type
     * @description_de Type name for hall group config
     */
    hall = "hall",
    /**
     * @title Postcode group config type
     * @description Type name for postcode group config
     * @title_de Postcode group config type
     * @description_de Type name for postcode group config
     */
    postcode = "postcode",
    /**
     * @title Collections group config type
     * @description Type name for Collections group config
     * @title_de Collections group config type
     * @description_de Type name for Collections group config
     */
    collections = "collections",
    /**
     * @title Employment type group config type
     * @description Type name for employment type group config
     * @title_de Employment type group config type
     * @description_de Type name for employment type group config
     */
    employment_type = "employment_type",
    /**
    * @title Job location group config type
    * @description Type name for job location group config
    * @title_de Job location group config type
    * @description_de Type name for job location group config
    */
    job_location = "job_location"
}

export interface GroupConfig {
    /**
     * @title Suggest group id
     * @description Id provided for suggest group
     * @title_de Suggest group id
     * @description_de Id provided for suggest group
     */
    id: string
    /**
     * @title Suggest group types
     * @description Various types provided for suggest group
     * @title_de Suggest group types
     * @description_de Various types for suggest group
     */
    type: GroupConfigType
    /**
     * @title Suggest group entity types
     * @description Various of entity types provided for suggest group
     * @title_de Suggest group entity types
     * @description_de Various of entity types provided for suggest group
     */
    entityType: EntityType
    /**
     * @title Suggest group msc number
     * @description msc number provided for suggest group
     * @title_de Suggest group msc number
     * @description_de msc number provided for suggest group
     */
    msc: number
}

export async function loadSuggestions(query: string, groupConfig: GroupConfig[] | any[]): Promise<SuggestResponse> {
    try {
        const data = await fetchDataWebService("/suggest", {
            searchstring: query,
            groupconfig: JSON.stringify(groupConfig.map((value) => (value as any).jsonData))
        })
        return { searchString: data.query, suggestGroups: data.suggestionGroups }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/suggest",
            params: { query },
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}


/*********************************************************************************************
 * SERIES_OF_TOPICS_USER LIST
 **********************************************************************************************/

export interface UsersListResponse {
    /**
     * @title Users list response count
     * @description Count number for users list response
     * @title_de Users list response count
     * @description_de Count number for users list response
     */
    count: number
    /**
     * @title Users list
     * @description Configuration for users list
     * @title_de Users list
     * @description_de Configuration for users list
     */
    users: SotUser[]
}

export async function loadUsersData(params: object, signal?: AbortSignal): Promise<UsersListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal)
        return { count: data.count, users: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, users: [] }
    }
}

/*********************************************************************************************
 * SPEAKER LIST
 **********************************************************************************************/

export interface SpeakersListResponse {
    /**
     * @title Speakers list response count
     * @description Count number for speakers list response
     * @title_de Speakers list response count
     * @description_de Count number for speakers list response
     */
    count: number
    /**
     * @title Persons list
     * @description Configuration for persons list
     * @title_de Persons list
     * @description_de Configuration for ersons list
     */
    persons: Person[]
}

export async function loadPersonsData(params: object, signal?: AbortSignal): Promise<SpeakersListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal)
        return { count: data.count, persons: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, persons: [] }
    }
}

export async function loadEventSpeakersData(params?: object, signal?: AbortSignal): Promise<SpeakersListResponse> {
    try {
        const data = await fetchDataWebService("/persons", params ?? [], signal)
        return { count: data.count, persons: data.persons }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, persons: [] }
    }
}

/*********************************************************************************************
 * SPEAKER DETAILS
 **********************************************************************************************/
export interface SpeakerResponse {
    content: Contact
}

export interface PersonDetailsParams {
    id: string
}
export async function loadPersonData(params: PersonDetailsParams): Promise<SpeakerResponse> {
    try {
        const data = await fetchDataWebService("/persondetails", params)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/persondetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

export interface UserDetailsParams {
    targetProfileId: string
    loggedInUserId: string
}
export async function loadUserData(params: UserDetailsParams): Promise<SpeakerResponse | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${params.loggedInUserId}/targetProfile/${params.targetProfileId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data.profile }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * TRADEMARK LIST
 **********************************************************************************************/
export interface TrademarkListResponse {
    count: number
    trademarks: Trademark[]
}

export async function loadTrademarksData(params: object, signal?: AbortSignal): Promise<TrademarkListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, trademarks: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * MAKE CONNECTION
 **********************************************************************************************/

export interface MakeConnectionResponse {
    content: any
}

export interface MakeConnectionParams {
    profileId: string
    targetProfileId: string
    message: string | null
    action: string
}

export async function doConnectAction(params: MakeConnectionParams): Promise<MakeConnectionResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/targetProfile/${params.targetProfileId}/connection`
    const body = {
        action: params.action,
        message: params.message
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * SET CONNECTION isViewed
 **********************************************************************************************/
export interface SetConnectionIsViewedResponse {
    content: any
}

export interface SetConnectionIsViewedParams {
    profileId: string
    viewedProfiles: string[]
}

export async function setConnectIsViewed(
    params: SetConnectionIsViewedParams
): Promise<SetConnectionIsViewedResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/personconnectionsviewed`
    const body = { viewedProfiles: params.viewedProfiles }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GET REQUESTS
 **********************************************************************************************/
export interface GetRequestsResponse {
    content: any
}

export interface GetRequestsParams {
    profileId: string
}

export async function getRequests(params: GetRequestsParams): Promise<GetRequestsResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/requests`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * APP DEVICE & Login
 **********************************************************************************************/

export async function createAppDevice(): Promise<TokenResponse | BackendServiceError> {
    const defaultRoute: string = `/appdevice/sot/${seriesOfTopicsName}`
    const body = {
        language: getActiveLanguage(),
        kind: "web"
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { beConnectionToken: data.beConnectionToken }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getDataPrivacyDocs(): Promise<DataPrivacyDoc | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/dataprivacy`
    const body = {
        lang: getActiveLanguage()
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            // TODO Handle multiple Docs?
            return (data as DataPrivacyDocs).dataPrivacyDocs[0]
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

function getCustomLoginData(): object {
    const queryParams: any = queryString.parse(window.location.search)
    const serverHint = queryParams.SIDwebserver
    if (serverHint) {
        return {
            SIDwebserver: serverHint
        }
    }
    return {}
}

export async function loginWithPassword(
    email: string,
    password: string,
    privacyDocs: DataPrivacyDocs
): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile`

    const customLoginData = getCustomLoginData()

    const body = {
        email: email,
        lang: getActiveLanguage(),
        dataPrivacyDocs: privacyDocs.dataPrivacyDocs,
        password: password,
        customData: customLoginData
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, undefined, true)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return getProfileFromBackendResponse(data)
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function loginWithSSO(
    ssoToken: string,
    privacyDocs: DataPrivacyDocs
): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile`

    const body = {
        ssoToken: ssoToken,
        lang: getActiveLanguage(),
        dataPrivacyDocs: privacyDocs.dataPrivacyDocs
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, undefined, true)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return getProfileFromBackendResponse(data)
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function personalizeTicket(redeemCode: string): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/ticket/topic/${topic}/personalize`

    const body = {
        lang: getActiveLanguage(),
        redeemCode: redeemCode
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, undefined, true)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data // todo
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function registerProfile(
    email: string,
    privacyDocs: DataPrivacyDocs
): Promise<{ profileId: string } | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile`
    const body = {
        email: email,
        lang: getActiveLanguage(),
        dataPrivacyDocs: privacyDocs.dataPrivacyDocs
    }

    function dummyFunction() {
        //added just so the dontForceLogout value can be passed without problems
        return
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, dummyFunction, true)
        if ((data as BackendServiceError).httpStatus || (data as BackendServiceError).responseJson?.errorCode) {
            return data
        } else {
            return { profileId: data.profileId }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function connectProfile(profileId: string, token: string): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/connection`
    const body = {
        userToken: token
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return getProfileFromBackendResponse(data)
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

function getProfileFromBackendResponse(data: any): ProfileResponse {
    const profile = {
        profileId: data.profile.id,
        remoteId: data.profile.remoteId,
        firstName: data.profile.firstName,
        lastName: data.profile.lastName,
        email: data.profile.email,
        infotext: data.profile.infotext,
        infotextDe: data.profile.infotextDe,
        interests: data.profile.interests,
        lookingfor: data.profile.lookingfor,
        offering: data.profile.offering,
        logoUrl: data.profile.logoUrl,
        matchActive: data.profile.matchActive,
        middleName: data.profile.middleName,
        industry: data.profile.industry,
        industryDe: data.profile.industryDe,
        countrycode: data.profile.countrycode,
        emailLanguage: data.profile.emailLanguage,
        mobile: data.profile.mobile,
        person: data.profile.person,
        phone: data.profile.phone,
        adress1: data.profile.adress1,
        adress2: data.profile.adress2,
        adress3: data.profile.adress3,
        city: data.profile.city,
        postalCode: data.profile.postalCode,
        position: data.profile.position,
        positionDe: data.profile.positionDe,
        salutation: data.profile.salutation,
        salutationDe: data.profile.salutationDe,
        company: data.profile.company,
        languages: data.profile.languages,
        areaOfResponsibility: data.profile.areaOfResponsibility,
        presence: data.profile.presence,
        facebook: data.profile.facebook,
        linkedIn: data.profile.linkedIn,
        googleplus: data.profile.googleplus,
        youTube: data.profile.youTube,
        twitter: data.profile.twitter,
        xing: data.profile.xing,
        pinterest: data.profile.pinterest,
        instagram: data.profile.instagram,
        type: data.profile.type,
        organizations: data.profile.organizations,
        eventDates: data.profile.eventDates,
        events: data.profile.events,
        invitingOrganization: data.profile.invitingOrganization,
        visible: data.profile.visible,
        showroomStandby: data.profile.showroomStandby,
        joboffers: data.profile.joboffers
    }

    return {
        beConnectionToken: data.beConnectionToken,
        profile: profile
    }
}

export interface DataPrivacyDocs {
    dataPrivacyDocs: [DataPrivacyDoc]
}

export interface DataPrivacyDoc {
    key: string
    url: string
}

/*********************************************************************************************
 * GET CONTACTS
 **********************************************************************************************/
export interface GetContactsResponse {
    content: ContactItem[]
}

export interface GetContactsParams {
    profileId: string
}

export async function getContacts(params: GetContactsParams): Promise<GetContactsResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/profiles/contacts`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data.items }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GET ORGANIZATION CONNECTION DETAILS
 **********************************************************************************************/

export interface SendOrganizationConnectionDetailsResponse {
    sotUser: string
    orga: string
    status: string
}

export interface SendOrganizationConnectionDetailsParams {
    profileId: string
    organizationId: string
    message: string | null
}

export async function sendOrganizationConnectionRequest(
    params: SendOrganizationConnectionDetailsParams
): Promise<SendOrganizationConnectionDetailsResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/organizationconnectionrequest/${params.organizationId}`
    const body = {
        message: params.message,
        status: "OPEN"
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "PUT", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { sotUser: data.sotUser, orga: data.orga, status: data.status }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export interface TokenResponse {
    beConnectionToken: string
}

export interface ProfileResponse {
    beConnectionToken: string
    profile: {
        profileId: string
        firstName?: string
        lastName?: string
        email: string
        type: string
        visible?: boolean
        showfloorStandby?: boolean
    }
}

export async function logout(profileId: string): Promise<TokenResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/logout`
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            try {
                await Auth.signOut()
            } catch (error: any) {
                logger.warn({
                    message: "BackendServices unable to logout from cognito",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            }
            return { beConnectionToken: data.beConnectionToken }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function cognitoLogin(profileId: string, beConnectionToken: string, email: string) {
    // although the pw parameter is nullable, it cannot be left empty (undefined, null or empty string) because amplify throws an error otherwise
    // see https://github.com/aws-amplify/amplify-js/issues/5623
    // The password must always be the same, because after the authentication by the guide backend we don't care about it and therefore Cognito should not check it.
    return Auth.signIn(profileId, "password", {
        topicName: branding.configuration.topicName,
        userPoolName: branding.configuration.userPoolName,
        beConnectionToken,
        email
    })
}

export async function cognitoCurrentAuthUser() {
    return Auth.currentAuthenticatedUser();
}


/*********************************************************************************************
 * GET CONNECTIONS
 **********************************************************************************************/
export interface GetConnectionsResponse {
    content: any
}

export interface GetConnectionsParams {
    profileId: string
    beConnectionToken: string
    depth: number
    firstResult: number
    topicFilter?: number
}

export async function getConnectionsService(params: GetConnectionsParams): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/profiles/networkingChartData`

    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { depth: params.depth },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

/*********************************************************************************************
 * GET CATEGORIES BY TOPIC
 **********************************************************************************************/
export interface GetCategoriesByTopicResponse {
    content: Array<Category>
}

export async function getCategoriesByTopic(): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/topic/${topic}/getcategoriesbytopic`

    try {
        const data = await fetchDataRest(defaultRoute, {}, "GET")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

export async function getRootCategoriesByTopic(rootCategories: Array<String>): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/topic/${topic}/getrootcategoriesbytopic`

    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", { rootCategories: rootCategories.join(",") })
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

/*********************************************************************************************
 * REMOVE FROM RELEVANT LIST
 **********************************************************************************************/

export interface removeFromRelevantListParams {
    requestedUser: string
    userToRemove: string
}

export async function removeFromRelevantList(params: removeFromRelevantListParams): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/removefromrecommendationlist/${params.requestedUser}/${params.userToRemove}`

    try {
        const data = await fetchDataRest(defaultRoute, {}, "DELETE")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

/*********************************************************************************************
 * PROFILE
 **********************************************************************************************/
export interface ProfileDataResponse {
    content: any
}

export interface ProfileImageChangeParams {
    profileId: string
    data: any
}

export async function changeProfileImage(params: ProfileImageChangeParams) {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/logo`
    const protocolAndHost = getProtocolAndHost()
    const accessDataString = localStorage.getItem(localStorageKey)
    const token = accessDataString ? JSON.parse(accessDataString).jwtToken : null

    const headers = {
        beConnectionToken: token ? token : seriesOfTopicsAccessToken,
        Accept: "application/json",
        "Accept-Encoding": "multipart/form-data"
    }

    try {
        const req = fetch(protocolAndHost + "/rest" + defaultRoute, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: headers,
            body: params.data
        })
        const resp = await req
        return new Promise<any>((resolve, reject) => {
            if (resp.status >= 200 && resp.status < 300) {
                return resolve(resp.json())
            } else {
                resp.json()
                    .then((res) => {
                        reject({ httpStatus: resp.status.toString(), httpStatusText: resp.statusText, responseJson: res })
                    })
                    .catch((e) => {
                        reject({ httpStatus: resp.status.toString(), httpStatusText: resp.statusText, responseJson: null })
                    })
            }
        })
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

export async function deleteProfileImage(profileId: string) {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/logo`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "DELETE")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

export interface InterestDataResponse {
    content: any
}
interface InterestCache {
    [key: string]: any
}
const interestCache: InterestCache = {}
export async function getInterest(): Promise<InterestDataResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/interests`
    const lang = getDefaultParams().lang
    try {
        if (interestCache[lang]) return { content: interestCache[lang] }
        const data = await fetchDataRest(defaultRoute, null, "GET")
        interestCache[lang] = data
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: [] }
    }
}

export interface ProfileDataResponse {
    content: any
}

export interface ProfileDataParams {
    profileId: string
    profileData: Object
}

export async function updateMyProfileData(params: ProfileDataParams): Promise<ProfileDataResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "PUT", params.profileData)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error // FIXME will break hard
    }
}

export async function getMyProfileData(): Promise<ProfileDataResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${getMyIdFromLocalStorage()}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined, undefined, true)
        return { content: getProfileFromBackendResponse({ profile: data }) }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            undefined,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error // FIXME will break hard
    }
}

/*********************************************************************************************
 * CREATE JON MEETING
 **********************************************************************************************/
export type MeetingKind =
    | "showroom"
    | "call"
    | "virtualCafe"
    | "calenderEntry"
    | "greenroom"
    | "roundtable"
    | "breakout"
    | "conferenceroom"
export type AttendeeRole = "recorder" | "member" | "moderator" | "viewer"
interface MediaPlacement {
    AudioFallbackUrl: string
    AudioHostUrl: string
    ScreenDataUrl: string
    ScreenSharingUrl: string
    ScreenViewingUrl: string
    SignalingUrl: string
    TurnControlUrl: string
}

interface ChimeMeeting {
    MeetingId: string
    ExternalMeetingId: string
    MediaRegion: string
    MediaPlacement: MediaPlacement
}

interface ChimeAttendee {
    ExternalUserId: string
    AttendeeId: string
    JoinToken: string
    Role: AttendeeRole
}

interface ChimeData {
    Meeting: ChimeMeeting
    Attendee: ChimeAttendee
}

interface MeetingData {
    id: string
    maxAttendees: number
    meetingKind: MeetingKind
    remainingDurationMillis: number
    maxDurationSeconds: number
    timeLimitChanged: boolean
}

export interface AttendeeData {
    id?: string
    name?: string
    role?: AttendeeRole
    avatarUrl?: string
    position?: string
    positionDe?: string
    connectionStatus?: string
    company?: string
    userType?: string
}

export interface ChimeMeetingData {
    meeting: MeetingData
    attendee: AttendeeData
    chime: ChimeData
}

/** Join meeting, without retries */
export async function createOrJoinMeeting(
    externalMeetingId: string,
    externalUserId: string
): Promise<ChimeMeetingData | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { autoLeave: true }, "PUT")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * LEAVE MEETING
 **********************************************************************************************/
export interface LeaveRoomResponse {
    message: string
}

export async function leaveRoom(
    externalMeetingId: string,
    externalUserId: string
): Promise<LeaveRoomResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { action: "leave" }, "DELETE")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function kick(
    externalMeetingId: string,
    externalUserId: string,
    reason?: string
): Promise<LeaveRoomResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { action: "kick", reason: reason ? reason : "" }, "DELETE", undefined)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function ban(
    externalMeetingId: string,
    externalUserId: string,
    reason?: string
): Promise<LeaveRoomResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { action: "ban", reason: reason ? reason : "" }, "DELETE", undefined)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAttendeeInfo(
    externalMeetingId: string,
    externalUserId: string
): Promise<AttendeeData | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined)
        return data.attendee
            ? (data.attendee as AttendeeData)
            : ({ httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
    }
}
export async function listAttendeeInfos(externalMeetingId: string): Promise<AttendeeData[] | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined)
        if (!Array.isArray(data)) return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
        const result = []
        for (var entry of data) {
            if (!entry.attendee) return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
            result.push(entry.attendee)
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
    }
}

export interface CheckForTimerUpdateResponse {
    maxMeetingLengthInSeconds?: number
    newRemainingDuration?: number
    timerUpdated: boolean
}

export async function checkForTimerUpdate(externalMeetingId: string): Promise<CheckForTimerUpdateResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/checkForTimerUpdate`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
    }
}

/*********************************************************************************************
 * LIST MEETING ATTENDEES
 **********************************************************************************************/
export interface MeetingAttendeeResponse {
    numAttendees: number
    maxAttendees: number
    attendees: ChimeAttendee[]
}

export interface ListMeetingAttendeeResponse {
    [externalMeetingId: string]: MeetingAttendeeResponse
}

export async function listMeetingAttendees(
    externalMeetingIds: string[]
): Promise<ListMeetingAttendeeResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/listAttendeesForMeetings`
    try {
        const data = await fetchDataRest(defaultRoute, { externalMeetingIds: externalMeetingIds }, "GET", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { externalMeetingIds: externalMeetingIds },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GREEN ROOM START/STOP LIVE
 *********************************************************************************************/
export interface ChannelResponse {
    channelId: string
    name?: string
    isLive: boolean
    isLocked: boolean
    url: string
    redundantUrl?: string
    thumbnailUrl: string
    currentEventDate?: EventDateBase
}

export async function startLive(channelId: string): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/start`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to go live with channel",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export type StopReason = "error" | "default"

export async function stopLive(channelId: string, reason: StopReason): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/stop`
    let requestBody = { reason: reason }
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", requestBody)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to stop live channel",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function lockChannel(channelId: string, authorizedUsers: string[]): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/lock`
    let requestBody = { authorizedUsers: authorizedUsers }
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", requestBody)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to lock channel",
            request: defaultRoute,
            params: requestBody,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function unlockChannel(channelId: string): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/unlock`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to unlock channel",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export interface RestartRecorderResponse {
    success: boolean
}
export async function restartRecorder(channelId: string): Promise<RestartRecorderResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/restartrecorder`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "POST", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to restart the channel recorder",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getChannelInfo(channelId: string): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "GET", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to fetch channel info",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getChannels(delay?: number): Promise<ChannelResponse[] | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channels`
    try {
        const data = await fetchDataRest(defaultRoute, { delayTime: delay }, "GET", undefined)
        return data.channels
    } catch (error: any) {
        logger.error({
            message: "Failed to fetch channels",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * SYNC FAVORITES
 **********************************************************************************************/
export interface SyncFavoritesResponse {
    content: any
}

export interface SyncFavoritesParams {
    profileId: string
    body: {
        currentTime: string
        lastSyncTime: string
        changedFavorites?: [
            {
                id: string
                kind: string
                deleted: boolean
                lastModified: string
            }
        ]
    }
}

export async function syncFavorites(params: SyncFavoritesParams): Promise<SyncFavoritesResponse> {
    const defaultRoute = `/sync/topic/${topic}/profile/${params.profileId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", params.body)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: [] }
    }
}

/*********************************************************************************************
 * SHOW ME AS CONTACT
 **********************************************************************************************/
export interface Settings {
    visible: boolean
    showroomStandby: boolean
}

export enum SettingsKey {
    VISIBLE = "VISIBLE",
    SHOWROOMSTANDBY = "SHOWROOMSTANDBY"
}

export async function setUserSetting(
    profileId: string,
    key: SettingsKey,
    value: string,
    organization?: string
): Promise<Settings | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/settings`
    try {
        const data = await fetchDataRest(
            defaultRoute,
            { key: key.toString(), value: value, organization: organization ? organization : "" },
            "POST",
            undefined
        )
        return data as Settings
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * PRODUCT DETAILS
 **********************************************************************************************/
export interface ProductDetailsResponse {
    content: Product
}

export interface ProductDetailsParams {
    productid: string
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadProductData(params: ProductDetailsParams): Promise<ProductDetailsResponse> {
    try {
        const data = await fetchDataWebService("/productdetails", params)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/productdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * PRODUCT LIST
 **********************************************************************************************/
export interface ProductsListResponse {
    count: number
    products: Product[]
}

export async function loadProductsData(params: object, signal?: AbortSignal): Promise<ProductsListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, products: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, products: [] }
    }
}

/*********************************************************************************************
 * NEWS DETAILS
 **********************************************************************************************/
export interface NewsResponse {
    content: News
}

export interface NewsDetailsParams {
    newsid: string
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadNewsData(params: NewsDetailsParams): Promise<NewsResponse> {
    try {
        const data = await fetchDataWebService("/newsdetails", params)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/newsdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * NEWS LIST
 **********************************************************************************************/
export interface NewsListResponse {
    count: number
    newsList: News[]
}

export async function loadNewsListData(params: object, signal?: AbortSignal): Promise<NewsListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, newsList: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * JOBOFFER LIST
 **********************************************************************************************/
export interface JobofferListResponse {
    count: number
    jobofferList: JobOffer[]
}

export async function loadJobofferListData(params: object, signal?: AbortSignal): Promise<JobofferListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, jobofferList: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * FEED NEWS LIST
 **********************************************************************************************/
export interface FeedNewsResponse {
    news: NewsItem[]
}

export async function loadFeedNews(): Promise<FeedNewsResponse | BackendServiceError> {
    const defaultRoute = `/news/list/${topic}`

    try {
        const data = await fetchDataRest(defaultRoute, null, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { news: data.items }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * TOGGLE USER FROM ORGANIZATION STAFF
 **********************************************************************************************/
export interface ToggleStaffResponse {
    content: any
}

export interface ToggleStaffParams {
    organizationId: string
    id: string
}

export async function getUserOrgaInfo(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const route: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/user/${params.id}`

    const data = await fetchDataRest(route, null, "GET")
    if ((data as BackendServiceError).httpStatus) {
        return data
    } else {
        return { content: data }
    }
}

export async function addUserToOrgaStaff(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/user`

    let userToSend = { userId: params.id, personFunction: branding.configuration.defaultStaffPersonFunction }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", userToSend)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            userToSend,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function removeUserFromOrgaStaff(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/user/${params.id}`

    try {
        const data = await fetchDataRest(defaultRoute, null, "DELETE")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function removePersonFromOrgaStaff(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/person/${params.id}`

    try {
        const data = await fetchDataRest(defaultRoute, null, "DELETE")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * REPORT USER
 **********************************************************************************************/

export interface ReportUserResponse {
    content: any
}

export interface ReportUserParams {
    reporterId: string
    reportProfileId: string
    reportType: string
    message: string
}

export async function reportUser(params: ReportUserParams): Promise<ReportUserResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/reporter/${params.reporterId}/reportProfile/${params.reportProfileId}/sendReportEmail`

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", {
            reportType: params.reportType,
            description: params.message
        })
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { reportType: params.reportType, description: params.message },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * TOGGLE ORGANIZATION DETAILSECTION FROM ORGANIZATION STAFF
 **********************************************************************************************/
export interface DetailSectionToggleResponse {
    showProducts: boolean
    showTrademarks: boolean
}
export enum DetailSectionEnum {
    PRODUCTS = "PRODUCTS",
    TRADEMARKS = "TRADEMARKS"
}

export async function toggleDetailSection(
    profileId: string,
    key: DetailSectionEnum,
    value: boolean,
    organizationId: string
): Promise<DetailSectionToggleResponse | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/organization/${organizationId}`
    try {
        const data = await fetchDataRest(defaultRoute, { key: key.toString(), value: value }, "POST", undefined)
        return data as DetailSectionToggleResponse
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * Presence status from dynamoDB
 **********************************************************************************************/

export enum PresenceType {
    DEFAULT = "unlisted",
    AVAILABLE = "Available",
    BUSY = "Busy",
    DONOTDISTURB = "Do not disturb",
    OFFWORK = "Off Work"
}

export enum AvatarType {
    VISITOR = "visitor",
    EXHIBITOR = "exhibitor",
    SPEAKER = "speaker"
}

export interface UserProps {
    id: string
    name?: string
    pictureUrl?: string
    presenceStatus?: PresenceType
    lastConnected: string
}

export interface UserResponse {
    getUser: {
        id: string
        name: string
        pictureUrl?: string
        presenceStatus: PresenceType
        lastConnected: string
    } | null
}

export interface DynamoDBErrors {
    errors: {
        errorType: string
    }[]
}

export async function createNewUser(input: UserProps): Promise<UserResponse | BackendServiceError | DynamoDBErrors> {
    const params = { input }
    try {
        const result = (await API.graphql(graphqlOperation(createUserLight, params))) as GraphQLResult<CreateUserMutation>
        return result as UserResponse
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUser failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function updateUserValues(input: UserProps): Promise<UserResponse | BackendServiceError | DynamoDBErrors> {
    const params = { input }
    try {
        const result = (await API.graphql(graphqlOperation(updateUserLight, params))) as GraphQLResult<UpdateUserMutation>
        const data = result.data
        // TODO better return type and null checks
        return {
            getUser: {
                id: data!.updateUser!.id,
                name: data!.updateUser!.name!,
                pictureUrl: data!.updateUser!.pictureUrl ?? undefined,
                presenceStatus: PresenceType[data!.updateUser!.presenceStatus! as keyof typeof PresenceType],
                lastConnected: data!.updateUser!.lastConnected
            }
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateUser failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function deleteUserById(id: string): Promise<UserResponse | BackendServiceError | DynamoDBErrors> {
    const params = { input: { id: id } }
    try {
        const result = (await API.graphql(graphqlOperation(deleteUserLight, params))) as GraphQLResult<DeleteUserMutation>
        const data = result.data
        // TODO better return type and null checks
        return {
            getUser: {
                id: data!.deleteUser!.id,
                name: data!.deleteUser!.name!,
                pictureUrl: data!.deleteUser!.pictureUrl ?? undefined,
                presenceStatus: PresenceType[data!.deleteUser!.presenceStatus! as keyof typeof PresenceType],
                lastConnected: data!.deleteUser!.lastConnected
            }
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteUser failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function getUserById(id: string): Promise<UserResponse | BackendServiceError> {
    const params = { id }
    try {
        const result = (await API.graphql(graphqlOperation(getUserLight, params))) as GraphQLResult<GetUserQuery>
        return result.data as UserResponse
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUser failed", params: params, error: error })
        return error
    }
}

export interface UserBatchPresenceResponse {
    id: string
    presenceStatus: PresenceType
    lastConnected: string
}
export async function getBatchPresenceByUserId(listIds: string[]): Promise<UserBatchPresenceResponse[] | BackendServiceError> {
    const params = { listIds: listIds }
    try {
        const result = (await API.graphql(
            graphqlOperation(batchGetUserPresenceLight, params)
        )) as GraphQLResult<BatchGetUserPresenceQuery>

        return result.data?.batchGetUserPresence as UserBatchPresenceResponse[]
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getBatchPresenceByUser failed", params: params, error: error })
        return {
            httpStatus: 500,
            httpStatusText: error.data,
            responseJson: error
        }
    }
}

/*********************************************************************************************
 * CALLING
 **********************************************************************************************/
export interface Meeting {
    id: string
    participants: MeetingParticipant[]
    start: Date
    end: Date
}

export interface User {
    id: string
    name: string
    pictureUrl?: string
}

export interface MeetingParticipant {
    id: string
    meeting: Meeting
    inviter: User
    invitee: User
    status: InviteStatus
    created: Date
}

export async function inviteToMeeting(
    callerId: string,
    hailingId: string,
    meetingId: string
): Promise<MeetingParticipant | null> {
    const params = {
        input: {
            meetingId: meetingId,
            inviterId: callerId,
            inviteeId: hailingId,
            status: InviteStatus.INVITING,
            created: new Date().toISOString()
        }
    }
    try {
        const result = (await API.graphql(graphqlOperation(createMeetingParticipant, params))) as any // TODO use correct type
        if (result?.data?.createMeetingParticipant) {
            return result.data.createMeetingParticipant
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createMeetingParticipant failed", params: params, error: error })
        return null
    }
}

export async function ensureMeeting(meetingId: string): Promise<string | undefined> {
    const params = { id: meetingId }
    try {
        const getMeetingResultAwait = (await API.graphql(
            graphqlOperation(doesMeetingExist, params)
        )) as GraphQLResult<GetMeetingLightQuery>
        if (getMeetingResultAwait?.data?.getMeeting?.id) return getMeetingResultAwait?.data?.getMeeting?.id
        const params2 = {
            input: {
                id: meetingId,
                start: new Date().toISOString()
            }
        }
        try {
            const createMeetingResultAwait = (await API.graphql(
                graphqlOperation(createMeeting, params2)
            )) as GraphQLResult<CreateMeetingMutation>
            return createMeetingResultAwait?.data?.createMeeting?.id
        } catch (error: any) {
            errorDynamoDBmessage({ message: "BackendServices createMeeting failed", params: params2, error: error })
            return undefined
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices doesMeetingExist failed", params: params, error: error })
        return undefined
    }
}

export async function ensureChatConversation(
    externalMeetingId: string,
    inviterId: string,
    hailedId: string,
    participantLimit: number
): Promise<boolean> {
    const conversationId = externalMeetingId.substr(3)
    const participants = await getConversationParticipants(conversationId, participantLimit)

    if (!participants) {
        const result = await createCallChatConversation(conversationId, inviterId, [hailedId])
        return !!result
    }

    if (participants?.length >= participantLimit) {
        return false
    }

    const inviterExists = participants.find((p) => p.id === inviterId)
    if (inviterExists) {
        const hailedExists = participants.find((p) => p.id === hailedId)
        if (hailedExists) {
            return true
        } else {
            try {
                const result = await addParticipantsToGroupChatConversation(conversationId, [hailedId])
                return !!result
            } catch {
                return false
            }
        }
    }

    // inviter not part of conversation => not authorized to add invitee
    return false
}

export async function updateMeetingInvite(id: string, status: InviteStatus) {
    const params = { input: { id: id, status: status } }
    try {
        const result = (await API.graphql(
            graphqlOperation(updateMeetingParticipantLight, params)
        )) as GraphQLResult<UpdateMeetingParticipantMutation>
        if (result?.data?.updateMeetingParticipant) {
            return result.data.updateMeetingParticipant
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateMeetingParticipant failed", params: params, error: error })
        return null
    }
}

export async function getMeetingHistoryEntries(id: string) {
    const params = { id: id, limit: 20 }
    try {
        const result = (await API.graphql(graphqlOperation(listMeetings, params))) as GraphQLResult<GetMeetingsQuery>
        if (result?.data?.getUser) {
            const inviteHistory: MeetingParticipant[] = []
            const incomingMeetings = result.data.getUser.incomingMeetings?.items
            incomingMeetings?.forEach((item: any) => {
                item.meeting.participants = item.meeting.participants.items
                inviteHistory.push(item)
            })
            const outgoingMeetings = result.data.getUser.outgoingMeetings?.items
            outgoingMeetings?.forEach((item: any) => {
                item.meeting.participants = item.meeting.participants.items
                inviteHistory.push(item)
            })
            inviteHistory.sort((a, b) => (a.created > b.created ? 1 : b.created > a.created ? -1 : 0))
            return inviteHistory
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices listMeetings failed", params: params, error: error })
        return null
    }
}

/*********************************************************************************************
 * CALENDAR
 **********************************************************************************************/

export async function createNewCalendarEntry(
    userId: string,
    participantsId: string[],
    title: string,
    start: Date,
    end: Date,
    description?: string,
    organization?: string
): Promise<CalendarEntry | null> {
    const input: CreateCalendarEntryInput = {
        userId: userId,
        title: title,
        description: description,
        start: start.toISOString(),
        end: end.toISOString(),
        organizationId: organization,
        status: organization ? CalendarEntryParticipationStatus.REQUESTED : undefined,
        topicName: branding.configuration.topicName,
        userPoolName: branding.configuration.userPoolName
    }

    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(createCalendarEntryLight, params))) as any
        const calendarEntry: CalendarEntry = result?.data?.createCalendarEntry

        if (calendarEntry) {
            const calendarEntryId = result.data.createCalendarEntry.id
            const items: CalendarEntryParticipation[] = []

            await Promise.all(
                participantsId.map(async (participantId) => {
                    if (userId !== participantId) {
                        const participant = await createNewCalendarEntryParticipation(
                            calendarEntryId,
                            participantId,
                            CalendarEntryParticipationStatus.REQUESTED,
                            start
                        )

                        if (participant) {
                            items.push(participant)
                        }
                    }
                })
            )

            const owner = await createNewCalendarEntryParticipation(
                calendarEntryId,
                userId,
                CalendarEntryParticipationStatus.ACCEPTED,
                start
            )
            if (owner) {
                items.push(owner)
            }
            const participants = {
                items: items
            }
            calendarEntry.participants = participants
            return calendarEntry
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function createNewCalendarEntryParticipation(
    calendarEntryId: string,
    participantId: string,
    status: CalendarEntryParticipationStatus,
    start: Date
): Promise<CalendarEntryParticipation | null> {
    const participantInput: CreateCalendarEntryParticipationInput = {
        userId: participantId,
        calendarEntryId: calendarEntryId,
        status: status,
        start: start.toISOString()
    }

    const params = { input: participantInput }
    try {
        const result = (await API.graphql(
            graphqlOperation(createCalendarEntryParticipationLight, params)
        )) as GraphQLResult<CreateCalendarEntryParticipationMutation>
        const participation = result?.data?.createCalendarEntryParticipation
        if (participation) {
            return {
                id: participation.id,
                status: participation.status,
                userId: participation.userId,
                user: {
                    id: participation.userId,
                    name: participation.user.name ? participation.user.name : "",
                    pictureUrl: participation.user.pictureUrl ? participation.user.pictureUrl : ""
                }
            }
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createCalendarEntryParticipation failed", params: params, error: error })
        return null
    }
}

export async function deleteCalendarEntryParticipationById(participationId: string) {
    const params = { input: { id: participationId } }
    try {
        const result = (await API.graphql(
            graphqlOperation(deleteCalendarEntryParticipation, params)
        )) as GraphQLResult<DeleteCalendarEntryParticipationMutation>
        return result?.data?.deleteCalendarEntryParticipation?.id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteCalendarEntryParticipation failed", params: params, error: error })
        return null
    }
}

export async function deleteCalendarEntryForUser(userId: string, calendarEntryId: string) {
    const condition: ModelCalendarEntryConditionInput = {
        userId: { eq: userId }
    }
    const params = { input: { id: calendarEntryId }, condition: condition }
    try {
        // Only the calendarEntry is deleted here
        // Lambda function "DeleteCalendarEntryMailSender" deletes participations and sends an email to each participant
        const result = (await API.graphql(
            graphqlOperation(deleteCalendarEntryLight, params)
        )) as GraphQLResult<DeleteCalendarEntryMutation>
        if (result?.data?.deleteCalendarEntry) {
            return result?.data?.deleteCalendarEntry
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices deleteCalendarEntry failed", params: params, error: error })
        return null
    }
}

export async function acceptOrganizationMeeting(calendarEntry: CalendarEntry, userId: string) {
    const input: UpdateCalendarEntryInput = {
        id: calendarEntry.id,
        userId: userId,
        status: CalendarEntryParticipationStatus.ACCEPTED
    }
    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(updateCalendarEntryLight, params))) as any
        const calendarEntry = result?.data?.updateCalendarEntry
        if (calendarEntry) {
            return createNewCalendarEntryParticipation(
                calendarEntry.id,
                userId,
                CalendarEntryParticipationStatus.ACCEPTED,
                new Date(calendarEntry.start)
            )
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function declineOrganizationMeeting(calendarEntry: CalendarEntry, userId: string) {
    const input: UpdateCalendarEntryInput = {
        id: calendarEntry.id,
        status: CalendarEntryParticipationStatus.DECLINED
    }
    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(updateCalendarEntryLight, params))) as any
        const calendarEntry = result?.data?.updateCalendarEntry
        if (calendarEntry) {
            return createNewCalendarEntryParticipation(
                calendarEntry.id,
                userId,
                CalendarEntryParticipationStatus.DECLINED,
                new Date(calendarEntry.start)
            )
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function updateCalendarEntryById(
    calendarEntry: CalendarEntry,
    title: string,
    start: Date,
    end: Date,
    description?: string
): Promise<CalendarEntry | null> {
    const input: UpdateCalendarEntryInput = {
        id: calendarEntry.id,
        start: start.toISOString(),
        end: end.toISOString(),
        title: title,
        description: description
    }

    const params = { input: input }
    try {
        const result = (await API.graphql(graphqlOperation(updateCalendarEntryLight, params))) as any
        const calendarEntry = result?.data?.updateCalendarEntry

        if (calendarEntry) {
            return calendarEntry
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryLight failed", params: params, error: error })
        return null
    }
}

export async function updateCalendarEntryParticipationStatus(
    calendarEntryParticipationId: string,
    userId: string,
    start?: Date,
    status?: CalendarEntryParticipationStatus
): Promise<CalendarEntryParticipationStatus | null> {
    const input: UpdateCalendarEntryParticipationInput = {
        id: calendarEntryParticipationId,
        start: start?.toISOString(),
        status: status
    }

    const params = { input: input }
    try {
        const result = (await API.graphql(
            graphqlOperation(updateCalendarEntryParticipationLight, params)
        )) as GraphQLResult<UpdateCalendarEntryParticipationMutation>

        if (result?.data?.updateCalendarEntryParticipation) {
            return result?.data?.updateCalendarEntryParticipation.status
        } else {
            return null
        }
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateCalendarEntryParticipation failed", params: params, error: error })
        return null
    }
}

export interface CalendarUser {
    id: string
    name: string
    pictureUrl: string
}

export interface CalendarEntryParticipation {
    id: string
    status: CalendarEntryParticipationStatus
    user: CalendarUser
    userId: string
}

export interface CalendarEntry {
    id: string
    title: string
    description?: string
    start: string
    end: string
    userId: string
    user: CalendarUser
    organizationId?: string
    participants: {
        items: CalendarEntryParticipation[]
    }
}

export interface CalendarEntries {
    items: {
        calendarEntryId: string
        calendarEntry: CalendarEntry
    }[]
    nextToken: string
}

export enum CalendarEntrySortType {
    ALL,
    PAST,
    FUTURE
}

export async function getCalendarEntriesAcceptedBetweenDates(
    userId: string,
    startTime: Date,
    endTime: Date,
    nextToken?: string
): Promise<CalendarEntries | null> {
    const dayBeforeStartTime = new Date(startTime.getTime() - 24 * 60 * 60 * 1000) // used to extend between range for dates that start before startTime but are finished in range of startTime and endTime
    const startInput: ModelStringKeyConditionInput = { between: [dayBeforeStartTime.toISOString(), endTime.toISOString()] }

    const params = {
        userIdStatus: userId + CalendarEntryParticipationStatus.ACCEPTED,
        start: startInput,
        limit: 50,
        nextToken: nextToken,
        sortDirection: ModelSortDirection.ASC
    }
    try {
        const result = (await API.graphql(graphqlOperation(getCalendarEntryParticipations, params))) as any

        const calendarEntries = result?.data?.calendarEntryParticipationByUserAndStatusSorted
        if (calendarEntries) {
            var participationIdsToDelete: string[] = []
            // Filter out calendarEntries that are null
            calendarEntries.items = calendarEntries.items.filter(
                (item: { calendarEntryId: string; calendarEntry: CalendarEntry | null; id: string }) => {
                    if (item.calendarEntry === null) {
                        participationIdsToDelete.push(item.id)
                    }
                    return item.calendarEntry !== null && !(moment(item.calendarEntry.end).toDate() <= startTime) //exclude dates that are start and finished in day before startTime
                }
            )

            // Delete participations with a calendarEntry that is null
            await Promise.all(
                participationIdsToDelete.map(async (participantId) => {
                    await deleteCalendarEntryParticipationById(participantId)
                })
            )

            return calendarEntries
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getCalendarEntryParticipations failed", params: params, error: error })
        return null
    }
}

export async function getCalendarEntries(
    userId: string,
    sortType: CalendarEntrySortType,
    status?: CalendarEntryParticipationStatus,
    nextToken?: string
): Promise<CalendarEntries | null> {
    const currentDate = new Date()
    const startInput: ModelStringKeyConditionInput =
        sortType === CalendarEntrySortType.FUTURE
            ? {
                ge: currentDate.toISOString()
            }
            : {
                lt: currentDate.toISOString()
            }
    const sortDirection: ModelSortDirection =
        sortType === CalendarEntrySortType.PAST ? ModelSortDirection.DESC : ModelSortDirection.ASC

    const params = {
        userIdStatus: userId + status,
        start: sortType === CalendarEntrySortType.ALL ? undefined : startInput,
        limit: 25,
        nextToken: nextToken,
        sortDirection: sortDirection
    }
    try {
        const result = (await API.graphql(graphqlOperation(getCalendarEntryParticipations, params))) as any // TODO use correct type

        const calendarEntries = result?.data?.calendarEntryParticipationByUserAndStatusSorted
        if (calendarEntries) {
            var participationIdsToDelete: string[] = []
            // Filter out calendarEntries that are null
            calendarEntries.items = calendarEntries.items.filter(
                (item: { calendarEntryId: string; calendarEntry: CalendarEntry | null; id: string }) => {
                    if (item.calendarEntry === null) {
                        participationIdsToDelete.push(item.id)
                    }
                    return item.calendarEntry !== null
                }
            )

            // Delete participations with a calendarEntry that is null
            await Promise.all(
                participationIdsToDelete.map(async (participantId) => {
                    await deleteCalendarEntryParticipationById(participantId)
                })
            )

            return calendarEntries
        }

        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getCalendarEntryParticipations failed", params: params, error: error })
        return null
    }
}

export interface OrganizationCalendarEntry {
    id: string
    title: string
    description?: string
    start: string
    end: string
    organizationId: string
    user: {
        id: string
        presenceStatus: string
        name: string
        pictureUrl?: string
    }
    participants: {
        items: {
            id: string
            status: CalendarEntryParticipationStatus
            user: {
                id: string
                presenceStatus: string
                name: string
                pictureUrl?: string
            }
        }[]
    }
}

export interface OrganizationCalendarEntryResponse {
    nextToken?: string
    items: CalendarEntry[]
}

export async function getOrganizationMeetingRequests(
    organizationId: string,
    listType: "upcoming" | "now" | "past",
    nextToken?: string
): Promise<OrganizationCalendarEntryResponse | null> {
    const date = new Date().toISOString()
    const params = {
        organizationIdStatus: organizationId + CalendarEntryParticipationStatus.REQUESTED,
        sortDirection: listType === "past" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
        limit: 25,
        nextToken: nextToken,
        start: listType === "upcoming" ? { ge: date } : { lt: date },
        filter: { topicName: { eq: topic }, end: listType === "now" ? { ge: date } : listType === "past" ? { lt: date } : undefined }
    }
    try {
        const result = (await API.graphql(graphqlOperation(listCalendarEntryByOrganization, params))) as any
        if (result?.data?.calendarEntryByOrganizationAndStatusSorted) {
            return result?.data?.calendarEntryByOrganizationAndStatusSorted
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices listCalendarEntryByOrganization failed", params: params, error: error })
        return null
    }
}

export interface CalendarAvailability {
    id: string
    userId: string
    user: CalendarUser
    start: string
    end: string
    available: boolean
}

export interface CalendarAvailabilities {
    items: [CalendarAvailability]
}

export async function findCalendarEntryById(calendarEntryId: string): Promise<CalendarEntry | undefined> {
    const params = { id: calendarEntryId, limit: branding.configuration.calendarEntryParticipantLimit }
    try {
        const result = (await API.graphql(
            graphqlOperation(getCalendarEntryLight, params)
        )) as GraphQLResult<GetCalendarEntryLightQuery>
        if (!result?.data?.getCalendarEntry) {
            return undefined
        }
        const calendarEntry = result.data.getCalendarEntry
        return calendarEntry as any
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getCalendarEntryLight failed", params: params, error: error })
        return undefined
    }
}

/*********************************************************************************************
 * ShareExhibitorMailNotification
 **********************************************************************************************/

export interface ShareExhibitorMailNotificationProps {
    targetId: string
    organizationId: string
    type: ShareTargetType
    notes: string
}

export async function shareTargetMailNotification(
    params: ShareExhibitorMailNotificationProps
): Promise<any | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/shareTarget/${params.organizationId}/targetProfile/${params.targetId}/sendShareMail`
    try {
        const data = await fetchDataRest(defaultRoute, { type: params.type }, "POST", { notes: params.notes })
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { notes: params.notes },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * UserSessions
 **********************************************************************************************/

export interface UserSessionProps {
    id: string
    userId: string
    location?: string
    locationLevel1?: string
    locationLevel2?: string
    locationLevel3?: string
    sotName?: string
    topic?: string
    time?: string
    ttl?: number
    source?: string
    queryHelper: string
    userType?: string
    countryCode?: string
}

export async function createNewUserSession(input: UserSessionProps, tryUpdate: boolean): Promise<string | undefined> {
    const params = createUserSessionParam(input)
    try {
        const resp = (await API.graphql(graphqlOperation(createUserSession, params))) as GraphQLResult<CreateUserSessionMutation>
        return resp.data?.createUserSession?.id
    } catch (error: any) {
        errorDynamoDBmessage({
            message: "BackendServices createUserSession failed" + (tryUpdate ? " - trying update" : " - no update"),
            params: params,
            error: error
        })
        if (tryUpdate) {
            return updateUserSessionData(input, false)
        }
        return undefined
    }
}

export async function closeUserSession(sessionId: string): Promise<string | undefined> {
    const params = { input: { id: sessionId } }
    try {
        const resp = (await API.graphql(graphqlOperation(deleteUserSession, params))) as GraphQLResult<DeleteUserSessionMutation>
        return resp.data?.deleteUserSession?.id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices closeUserSession failed", params: params, error: error })
        return undefined
    }
}

export async function updateUserSessionData(input: UserSessionProps, tryCreate: boolean): Promise<string | undefined> {
    const params = createUserSessionParam(input)
    try {
        const resp = (await API.graphql(
            graphqlOperation(updateUserSessionLight, params)
        )) as GraphQLResult<UpdateUserSessionMutation>
        return resp.data?.updateUserSession?.id
    } catch (error: any) {
        // Try self heal
        errorDynamoDBmessage({
            message: "BackendServices updateUserSessionData failed" + (tryCreate ? " - trying create" : " - no create"),
            params: params,
            error: error
        })
        if (tryCreate) {
            return createNewUserSession(input, false)
        }
        return undefined
    }
}

function createUserSessionParam(input: UserSessionProps) {
    const param = { input }
    param.input.sotName = seriesOfTopicsName
    param.input.time = new Date().toISOString()
    param.input.ttl = Math.round(new Date().getTime() / 1000) + 6 * 60
    param.input.queryHelper = "X"
    param.input.topic = topic
    return param
}

export interface UsersByLocationResponse {
    getCurrentLocationCounts: {
        id: string
        cnt: number
        lastConnected: string
    } | null
}

export async function getUsersByLocation(location: string): Promise<number> {
    const id = location === "" ? seriesOfTopicsName : seriesOfTopicsName + "#" + location
    const params = { id: id }
    try {
        const result = (await API.graphql(graphqlOperation(getOnlineUsers, params))) as GraphQLResult<getOnlineUsersQuery>
        return result.data?.getCurrentLocationCounts?.cnt ? result.data.getCurrentLocationCounts.cnt : 0
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUsersByLocation failed", params: params, error: error })
        return 0
    }
}

export async function trackStartOfCall(
    sessionId: string,
    userId: string,
    callId: string,
    countryCode?: string,
    userType?: string
): Promise<string | DynamoDBErrors> {
    const startTime = new Date().toISOString()
    const id = sessionId + "#" + startTime
    const param = {
        id: id,
        userId: userId,
        sotName: seriesOfTopicsName,
        topic: topic,
        actionType: UserActionType.CALL,
        param: callId,
        startTime: startTime,
        duration: 0,
        countryCode,
        userType
    }
    const params = { input: param }
    try {
        await API.graphql(graphqlOperation(createUserAction, params))
        return id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function trackStartOfStream(
    sessionId: string,
    userId: string,
    event: string,
    countryCode?: string,
    userType?: string
): Promise<string | DynamoDBErrors> {
    const startTime = new Date().toISOString()
    const id = sessionId + "#" + startTime
    const param = {
        id: id,
        userId: userId,
        sotName: seriesOfTopicsName,
        actionType: UserActionType.STREAM,
        topic: topic,
        param: event,
        startTime: startTime,
        duration: 0,
        countryCode,
        userType
    }
    const params = { input: param }
    try {
        await API.graphql(graphqlOperation(createUserAction, params))
        return id
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function trackEndOfCall(trackingId: string, type: UserActionType): Promise<string | DynamoDBErrors> {
    const startTime = new Date(trackingId.split("#")[1])
    const endTime = new Date()
    const duration = endTime.getTime() - startTime.getTime()
    const param = {
        id: trackingId,
        endTime: endTime.toISOString(),
        duration: duration
    }
    const condition: ModelUserActionConditionInput = { actionType: { eq: type } }
    const params = { input: param, condition }
    try {
        await API.graphql(graphqlOperation(updateUserAction, params))
        return trackingId
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices updateUserAction failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function trackOrganizationDetailsPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    searchKrit?: string
): Promise<string> {
    return trackVisit(profileId, organizationId, ("DETAIL#" + source) as UserOrganizationVisitType, searchKrit)
}

export async function trackEventDateDetailsPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    eventDateId: string
): Promise<string> {
    return trackVisit(profileId, organizationId, ("EVENTDATE#" + source) as UserOrganizationVisitType, eventDateId)
}

export async function trackLiveStreamDetailsPageVisit(profileId: string, organizationId: string, eventDateId: string) {
    return trackVisit(profileId, organizationId, "STREAM#DETAIL", eventDateId)
}

export async function trackOnDemandDetailsPageVisit(profileId: string, organizationId: string, eventDateId: string) {
    return trackVisit(profileId, organizationId, "VOD", eventDateId)
}

// getrackte Quellen
export type UserOrganizationVisitSource =
    // für OrganizationDetails
    | "FLOOR"
    | "FLOORSPONSOR"
    | "SPONSORS"
    | "COUPON"
    | "BANNER"
    | "SIDEBAR"
    | "LOBBY"
    | "LOBBYSPONSOR"
    | "MYPAGE"
    | "MYPAGESPONSOR"
    | "VC"
    | "BOOKMARK"
    | "PROFILE"
    | "EVENTDATE"
    // 'NOTIFICATION' |
    // // für Livestreams
    // 'ORGANIZATION' |
    // 'EVENTDATE' |
    // // alle?
    | "PERSON"
    | "SEARCH"
    | "PRODUCT"
    | "TRADEMARK"
    | "COLLECTION"
    | "NEWS"
    | "JOBOFFER"
    | "UNKNOWN"

export type UserOrganizationVisitType =
    // 'DETAIL#FLOOR' |
    // 'DETAIL#SPONSORS' |
    // 'DETAIL#COUPON' |
    // 'DETAIL#BANNER' |
    // 'DETAIL#SIDEBAR' |
    // 'DETAIL#LOBBY' |
    // 'DETAIL#VC' |
    // 'DETAIL#BOOKMARK' |
    // 'DETAIL#PROFILE' |
    // 'DETAIL#EVENTDATE' |
    | "EXPO"
    | "CALENDARENTRY#CLICK"
    | "CALENDARENTRY#SENT"
    | "INTEREST#CLICK"
    | "INTEREST#SENT"
    | "RECOMMENDATION#CLICK"
    | "RECOMMENDATION#SENT"
    | "VC#DETAIL"
    | "VC#LOBBY"
    | "VC#VC"
    | "VCROOM"
    | "LINK"
    | "MEDIA#PREVIEW"
    | "MEDIA#DOWNLOAD"
    | "STREAM#DETAIL"
    | "VOD"
    // 'STREAM#EVENTDATE' |
    // 'EVENTDATE#DETAIL' |
    // 'EVENTDATE#SCHEDULE' |
    // 'EVENTDATE#SPEAKER' |
    // 'EVENTDATE#BOOKMARK' |
    // 'EVENTDATE#SEARCH' |
    | "COUPON"
    | "MAGAZINE"
    | "PRESS"

interface UserOrganizationVisitParam {
    time: string
    type: UserOrganizationVisitType
    targetId?: string
}

export async function trackVisit(
    profileId: string,
    organizationId: string,
    type: UserOrganizationVisitType,
    targetId?: string
): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/profile/${profileId}/organization/${organizationId}`
    const payload: UserOrganizationVisitParam = { time: new Date().toISOString(), type: type }
    if (targetId) {
        payload.targetId = targetId
    }
    try {
        const result = await fetchDataRest(defaultRoute, null, "POST", payload)
        if ((result as any).httpStatus) {
            return "FAIL"
        }
        return "SUCCESS"
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            payload,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export interface LiveTrackingDatapoint {
    visitDate: string
    cnt: number
    type: string
}

export async function getLiveTrackingResults(
    profileId: string,
    organizationId: string,
    type: string,
    startDate: string,
    endDate: string
): Promise<LiveTrackingDatapoint[] | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/profile/${profileId}/organization/${organizationId}`
    try {
        const result = await fetchDataRest(defaultRoute, { type, startDate, endDate }, "GET")
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export interface SeriesOfTopicUserWithoutCta {
    profileId: string
    firstName?: string
    lastName?: string
    company?: string
    position?: string
    logoUrl?: string
}

export interface SeriesOfTopicUserWithCta extends SeriesOfTopicUserWithoutCta {
    detail: number
    expo: number
    ce: number
    interest: number
    rec: number
    vc: number
    link: number
    media: number
    live: number
    eventDate: number
    coupon: number
    press: number
    magazine: number
    lastvisit: string
    marked?: string
    markedBy?: string
    markedtext?: string
    optIn?: boolean
}

export interface UserOrganizationVisit {
    marked?: string
    markedBy?: string
    markedtext?: string
}

export async function markVisitor(
    visitorId: string,
    organizationId: string,
    profileId: string,
    markedText: string
): Promise<UserOrganizationVisit | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            {
                profileId: profileId,
                organizationId: organizationId,
                visitorId: visitorId,
                markedText: markedText,
                time: new Date().toISOString(),
                marked: true
            },
            "POST"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result as UserOrganizationVisit
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function unmarkVisitor(
    visitorId: string,
    organizationId: string,
    profileId: string
): Promise<UserOrganizationVisit | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { profileId: profileId, organizationId: organizationId, visitorId: visitorId, marked: false },
            "POST"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result as UserOrganizationVisit
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export interface SeriesOfTopicUserWithCtaResponse {
    hasNext: boolean
    nextTime?: string
    data: SeriesOfTopicUserWithCta[]
}

export async function getVisitorsWithCtas(
    organizationId: string,
    optInOnly: boolean,
    unmarked: boolean,
    nextDate?: string
): Promise<SeriesOfTopicUserWithCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors`
    const queryParams: any = { organizationId, unmarked, optInOnly, limit: 20 }
    if (nextDate) {
        queryParams.nextDate = nextDate
    }
    try {
        const result = await fetchDataRest(defaultRoute, queryParams, "GET")
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export interface SeriesOfTopicUserWithoutCtaResponse {
    hasNext: boolean
    data: SeriesOfTopicUserWithoutCta[]
}

export async function getOrganizationGuests(
    organizationId: string,
    itemsPerPage: number,
    page: number,
    searchString: string
): Promise<SeriesOfTopicUserWithoutCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/guests`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { organizationId: organizationId, itemsPerPage: itemsPerPage, page: page, searchString: searchString },
            "GET"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function organizationGuestsExport(organizationId: string): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/guests/csv`
    const params = { organizationId: organizationId }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, "guests.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export async function getOrganizationContacts(
    organizationId: string,
    itemsPerPage: number,
    page: number,
    searchString: string
): Promise<SeriesOfTopicUserWithoutCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/contacts`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { organizationId: organizationId, itemsPerPage: itemsPerPage, page: page, searchString: searchString },
            "GET"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function organizationContactsExport(profileId: string, organizationId: string): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/contacts/csv`
    const params = { profileId: profileId, organizationId: organizationId }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, "contacts.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export async function getUserDb(
    organizationId: string,
    itemsPerPage: number,
    page: number,
    searchString: string
): Promise<SeriesOfTopicUserWithoutCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/users`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { organizationId: organizationId, itemsPerPage: itemsPerPage, page: page, searchString: searchString },
            "GET"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function userDbExport(profileId: string, organizationId: string): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/users/csv`
    const params = { profileId: profileId, organizationId: organizationId }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, "users.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export async function visitorsWithCtasExport(organizationId: string, optInOnly: boolean): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors/csv`
    const params = { organizationId, optInOnly }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, optInOnly ? "leads.csv" : "visitors.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

async function saveFile(resp: Response, fileName: string): Promise<string> {
    const blob = await resp.blob()
    FileSaver.saveAs(blob, fileName)
    return "DONE"
}

export async function listUsersInAllLoungesOrCalls(specificCallParam?: string): Promise<SessionUserMap> {
    const [inLounge, inCall] = await Promise.all([
        listUsersInAllConferenceRooms(),
        listUsersInAllLounges(),
        listUsersInAllCalls(specificCallParam)
    ])
    return mergeSessionUserMaps(inCall, inLounge)
}

function mergeSessionUserMaps(map1: SessionUserMap, map2: SessionUserMap) {
    const result: SessionUserMap = Object.assign({}, map1)
    for (const [key, val] of Object.entries(map1)) {
        if (!result[key]) {
            result[key] = []
        }
        result[key] = unique((x) => x.user.id, val)
    }

    for (const [key, val] of Object.entries(map2)) {
        if (!result[key]) {
            result[key] = []
        }
        result[key] = unique((x) => x.user.id, result[key].concat(val))
    }
    return result
}

function unique(idFun: (x: any) => string, arr: any[]): SessionUser[] {
    const ids: string[] = []
    const result: any[] = []
    arr.forEach((x: any) => {
        const id = idFun(x)
        if (ids.indexOf(id) < 0) {
            ids.push(id)
            result.push(x)
        }
    })
    return result
}

export type SessionUser = {
    id: string
    user?: {
        id: string
        name: string
        pictureUrl: string
    }
}

export interface SessionUserMap {
    [loungeOrRoom: string]: SessionUser[]
}

async function listUsersInAllConferenceRooms(): Promise<SessionUserMap> {
    const params = {
        sotName: seriesOfTopicsName,
        locationLevel1: { beginsWith: "/meeting/cr" }
    }
    try {
        const lounges: SessionUserMap = {}
        const result = (await API.graphql(
            graphqlOperation(userSessionsByLocation, params)
        )) as GraphQLResult<UserSessionsByLocationQuery>

        if (result.data?.userSessionsByLocation?.items) {
            result.data.userSessionsByLocation.items
                .filter((x: any) => x.user)
                .forEach((item: any) => {
                    const loungeId = (item.locationLevel1 as string).substring(12)
                    if (!lounges[loungeId]) {
                        lounges[loungeId] = []
                    }
                    lounges[loungeId].push(item)
                })
        }
        return lounges
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userSessionsByLocation failed", params: params, error: error })
        return {}
    }
}

async function listUsersInAllLounges(): Promise<SessionUserMap> {
    const params = {
        sotName: seriesOfTopicsName,
        locationLevel2: { beginsWith: "/meetings/" }
    }
    try {
        const lounges: SessionUserMap = {}
        const result = (await API.graphql(
            graphqlOperation(userSessionsByLocation, params)
        )) as GraphQLResult<UserSessionsByLocationQuery>
        if (result.data?.userSessionsByLocation?.items) {
            result.data.userSessionsByLocation.items
                .filter((x: any) => x.user)
                .forEach((item: any) => {
                    const loungeId = (item.locationLevel2 as string).substring(10)
                    if (!lounges[loungeId]) {
                        lounges[loungeId] = []
                    }
                    lounges[loungeId].push(item)
                })
        }
        return lounges
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userSessionsByLocation failed", params: params, error: error })
        return {}
    }
}

interface ChannelParticipants {
    nextToken?: string
    items: SessionUser[]
}
export async function listChannelParticipants(channelId: string, nextToken?: string): Promise<ChannelParticipants | null> {
    const params = {
        sotName: seriesOfTopicsName,
        locationLevel2: { beginsWith: `/channel/${channelId}` },
        limit: 25,
        nextToken: nextToken
    }
    try {
        const result = (await API.graphql(
            graphqlOperation(userSessionsByLocation, params)
        )) as GraphQLResult<UserSessionsByLocationQuery>
        if (result.data?.userSessionsByLocation?.items) {
            const items = result.data.userSessionsByLocation.items.map((x: any) => x.user)
            return { items: items, nextToken: result.data.userSessionsByLocation.nextToken ?? undefined }
        }
        return null
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices userSessionsByLocation failed", params: params, error: error })
        return null
    }
}

async function listUsersInAllCalls(specificCallParam?: string): Promise<SessionUserMap> {
    const beginsWithParam = specificCallParam ?? "virtualCafe#"

    const params = {
        actionType: UserActionType.CALL,
        duration: { eq: 0 },
        filter: { sotName: { eq: seriesOfTopicsName }, param: { beginsWith: beginsWithParam } }
    }
    try {
        const lounges: SessionUserMap = {}
        const result = (await API.graphql(
            graphqlOperation(usersInCallsInLounge, params)
        )) as GraphQLResult<usersInCallsInLoungeQuery>
        if (result.data?.byActionType?.items) {
            result.data.byActionType.items
                .filter((x: any) => x.user)
                .forEach((item: any) => {
                    const roomId = (item.param as string).substring(beginsWithParam.length)
                    const [loungeId] = roomId.split("/")
                    if (!lounges[loungeId]) {
                        lounges[loungeId] = []
                    }
                    lounges[loungeId].push(item)
                    if (!lounges[roomId]) {
                        lounges[roomId] = []
                    }
                    lounges[roomId].push(item)
                })
        }
        return lounges
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices usersInCallsInLounge failed", params: params, error: error })
        return {}
    }
}

/*********************************************************************************************
 * PRIVACY POLICY
 **********************************************************************************************/

export const createPrivacyUserQuestionId = (organizationId: string) => {
    if (branding.globalOptIn) {
        return "vg_tracking_" + branding.configuration.topicName
    }
    return "vg_tracking_" + branding.configuration.topicName + "/" + organizationId
}

export async function getPrivacyUserAnswer(sotUserId: string, organizationId: string): Promise<PrivacyUserAnswer | undefined> {
    const defaultRoute: string = `/privacypolicy/topic/${topic}/sotuserid/${sotUserId}/answer`
    const params = { organizationId }
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as PrivacyUserAnswer[]).length > 0)
            return (data as PrivacyUserAnswer[]).find(
                (pa: PrivacyUserAnswer) => pa.questionId === createPrivacyUserQuestionId(organizationId)
            )
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return undefined
    }
}

export interface addPrivacyPolicyAnswerParams {
    sotUserId: string
    answerText: string
    questionText: string
    organizationId: string
}

export async function addPrivacyPolicyAnswer(
    params: addPrivacyPolicyAnswerParams
): Promise<PrivacyUserAnswer | BackendServiceError> {
    const defaultRoute: string = `/privacypolicy/topic/${topic}/sotuserid/${params.sotUserId}/privacy`
    const body = {
        answerText: params.answerText,
        questionText: params.questionText,
        questionId: createPrivacyUserQuestionId(params.organizationId)
    }
    try {
        return await fetchDataRest(defaultRoute, null, "POST", body)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * UnreadCount within CommunicationArea
 **********************************************************************************************/

export type UnreadObject = {
    id: string
    requests?: number
    contacts?: number
    conversations?: number
    schedules?: number
}

export async function getUnreadCounterUser(userId: string): Promise<UnreadObject | DynamoDBErrors> {
    const params = { id: userId }
    try {
        const result = (await API.graphql(graphqlOperation(getUnreadCounter, params))) as GraphQLResult<GetUnreadCounterQuery>
        const data = result.data?.getUnreadCounter
        return data as UnreadObject
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices getUnreadCounter failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function createUnreadCounterUser(param: UnreadObject): Promise<UnreadObject | DynamoDBErrors> {
    const params = { input: param }
    try {
        const result = (await API.graphql(
            graphqlOperation(createUnreadCounter, params)
        )) as GraphQLResult<CreateUnreadCounterMutation>
        const data = result.data?.createUnreadCounter
        return data as UnreadObject
    } catch (error: any) {
        errorDynamoDBmessage({ message: "BackendServices createUnreadCounter failed", params: params, error: error })
        return error as DynamoDBErrors
    }
}

export async function correctUnreadCounterConversations(profileId: string, ddbCounterDifference?: number) {
    // FIXME
    // const params = { profileId: profileId, ddbCounterDifference: ddbCounterDifference }
    // const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/correctUnreadCounterConversations`
    // try {
    //     fetchDataRest(defaultRoute, params, "POST")
    // } catch (error: any) {
    //     logger.error({ message: "BackendServices fetch failed", request: defaultRoute, params, errorMessage: error.message, errorStack: error.stack });
    // }
}

/*********************************************************************************************
 * UserVirtualCafeAccess & UserEventDateAccess
 **********************************************************************************************/

export interface UserRestrictedAreaAccess {
    id: string
    restrictedAreaId: string
    restrictedAreaType: string
    user: VirtualCafeAccessUser
    status: string
    requestReason: string
    topic: string
    createdAt: string
}

export interface RestrictedAreaType {
    resourceName: string
    pathName: string
    value: string
}

export const RestrictedAreaTypes = {
    VirtualCafe: { value: "virtualCafe", resourceName: "uservirtualcafeaccess", pathName: "virtualcafe" } as RestrictedAreaType,
    RoundTable: { value: "roundtable", resourceName: "userroundtableaccess", pathName: "roundtable" } as RestrictedAreaType,
    PrivateEvent: { value: "privateEvent", resourceName: "usereventdateaccess", pathName: "privateevent" } as RestrictedAreaType,
    ConferenceRoom: {
        value: "conferenceroom",
        resourceName: "userconferenceroomaccess",
        pathName: "conferenceroom"
    } as RestrictedAreaType
}

export async function getUserRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "GET")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function sendRequestForRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    requestReason: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "PUT", { requestReason: requestReason })
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { requestReason: requestReason },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function acceptOrDeclineUserRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    action: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "POST", { action: action })
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { action: action },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function deleteUserRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "DELETE", {})
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getUserRestrictedAreaAccessList(
    userId: string,
    restrictedAreaId: string,
    params: object,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAccessStatusForAllEventDates(): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${RestrictedAreaTypes.PrivateEvent.resourceName}/topic/${topic}/privateevent/access`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAccessStatusForAllVirtualCafes(): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${RestrictedAreaTypes.VirtualCafe.resourceName}/topic/${topic}/virtualcafe/access`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAccessStatusForAllConferenceRooms(): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${RestrictedAreaTypes.ConferenceRoom.resourceName}/topic/${topic}/conferenceroom/access`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

function forceLogout(logoutReason: string) {
    if (localStorage.getItem("logoutInProcess")) {
        return
    }

    if (localStorage.getItem(localStorageKey)) {
        localStorage.removeItem(localStorageKey)
        localStorage.setItem("logoutReason", logoutReason)
        window.location.reload()
    }
}
