// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { API, graphqlOperation } from "aws-amplify"
import { useEffect, useState } from "react"
import * as React from "react"
import { Button } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import Observable from "zen-observable-ts"
import {
    ConversationType,
    OnUserConversationCreatedByConversationLightSubscription,
    OnUserConversationDeletedByConversationLightSubscription
} from "../API"
import {
    checkConversationExists,
    getConversationParticipants,
    loadEventDateDetails,
    loadExhibitorData,
    User
} from "../backendServices/BackendServices"
import branding from "../branding/branding"
import { ChatConversationParam } from "../communicationArea/ChatPage"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import GuestUserBanner from "../contentArea/guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../contentArea/guestUserBanner/GuestUserBannerSharedState"
import { useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import {
    onUserConversationCreatedByConversationLight,
    onUserConversationDeletedByConversationLight
} from "../graphql/ownSubscriptions"
import Breadcrumb, { BreadcrumbItem } from "../navigationArea/Breadcrumb"
import TopBar from "../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../tracking/RouteTracker"
import CenteredLoader from "../ui/CenteredLoader"
import { accessPresenceState, EventType } from "../ui/PresenceIndicator"
import { isPostEventPhase } from "../utils/EventPhaseChecker"
import ContentVideo from "./components/ContentVideo"
import Controls from "./components/Controls"
import RemoteVideoGroup from "./components/RemoteVideoGroup"
import Roster from "./components/Roster"
import { getMeetingKindFromExternalMeetingId, MeetingStatusCode, useChimeContext } from "./context/ChimeContext"
import { useDevices } from "./hooks/useDevices"
import { checkIfLocalStorageItemExists } from "./components/DeviceSwitcher"
import { detect } from "detect-browser"

const ConferenceRoomRoot = styled.div<{ guestBannerHeight: number }>`
    position: relative;
    height: calc(100vh - 60px - ${(props) => props.guestBannerHeight + "px"});
    background-color: ${branding.mainInfoColor ?? "#000"};
    color: ${branding.mainInfoColor};

    .customLoader {
        border: none;
    }
`

export const Message = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 1.5rem;
    margin: auto;
    color: #fff;
    padding: 30px;
`

const RoomContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const VideoAndControl = styled.div<{ guestBannerHeight: number }>`
    flex-grow: 8;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 60px - ${(props) => props.guestBannerHeight + "px"});
    max-height: calc(100vh - 60px - ${(props) => props.guestBannerHeight + "px"});
`

const SidebarRoster = styled.div`
    flex-grow: 2;
`
const ConferenceContainer = styled.div<{ guestBannerHeight: number }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: calc(100vh - 60px - ${(props) => props.guestBannerHeight + "px"});

    border-right: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
`

const BreadcrumbRoot = styled.div`
    background-color: ${branding.mainInfoColor ?? "black"};
    border-bottom: 1px solid #fff;
    height: 62px;
    padding-bottom: 20px;
`

const ModMutedBar = styled.div<{ isRosterOpen: boolean | null }>`
    position: absolute;
    top: 70px;
    width: ${(props) => (props.isRosterOpen ? "calc(100% - 350px)" : "calc(100% - 80px)")};
    background: #00f;
    color: #fff;
    z-index: 1;
    padding: 5px 15px;
    margin: 0 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    & button {
        border: 1px solid #fff;
        margin-left: 20px;
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
`

const StyledButton = styled(Button)`
    @media (max-width: 1156px) {
        margin-top: 5px;

        &.left {
            margin-left: -20px;
        }
    }
`

const StyledControls = styled(Controls)`
    @media (max-width: 1440px) {
        width: 75%;
    }
`
export const replaceReasonPlaceholderText = (template: string, reason: string, defaultText: string) => {
    //var template = strings.globalTexts.guestRestrictionMessage
    let contentString

    if (reason !== "") {
        contentString = template.split("{$reason}").join(reason)
    } else {
        contentString = defaultText
    }

    return contentString
}

enum ChatType {
    CALENDARENTRY,
    CALL_PRIVATE,
    CALL_GROUP,
    CALL_UNKNOWN
}

const browserCheckResult = detect()
const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"
const isSafari = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "safari"

export default function ConferenceRoom() {
    const loggedInState = useLoggedInState()
    const appState = useAppState()
    const { roomName }: any = useParams()
    const chime = useChimeContext()
    const devices = useDevices()
    const meetingStatus = chime.getMeetingStatus()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([])
    const roster = chime.getRoster()
    // Only interested in changes to the participant Names
    const rosterChangedValue = Object.keys(roster)
        .map((key) => roster[key]?.name)
        .join()
    const meetingStatusCode = meetingStatus.meetingStatus
    const [chatType, setChatType] = useState<ChatType | undefined>()
    const [lastChatType, setLastChatType] = useState<ChatType | undefined>()
    const [conversationParticipants, setConversationParticipants] = useState<User[]>([])
    const [isExistingMeeting, setIsExistingMeeting] = useState<boolean | undefined>()
    const profileId = loggedInState.user()?.profileId
    const history = useHistory()

    const locations = calcBreadcrumbLocations(strings)

    //Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0

    const meetingKind = getMeetingKindFromExternalMeetingId(roomName)

    useEffect(
        () => {
            const abortController = new AbortController()
            if (meetingKind === "virtualCafe") {
                loop: for (const meetingRoomGroup of strings.meetingRoomGroups) {
                    for (const meetingRoom of meetingRoomGroup.meetingRooms) {
                        if (meetingRoom.id === roomName.substring(3)) {
                            // this is hack, as url's changed
                            locations[0] = "/meetings"
                            locations[1] = `/meetings/${meetingRoomGroup.id}`
                            setBreadcrumb([
                                {
                                    to: locations[0],
                                    name: strings.sideIconBar.virtualcafeMenuText
                                },
                                {
                                    to: locations[1],
                                    name: meetingRoomGroup.title
                                },
                                {
                                    to: locations[2],
                                    name: meetingRoom.title
                                }
                            ])
                            break loop
                        }
                    }
                }
            } else if (meetingKind === "showroom") {
                const organizationid = roomName.substr(3)
                loadExhibitorData({
                    organizationid: organizationid,
                    hideNewsdata: true
                })
                    .then((resp) => {
                        setBreadcrumb([
                            {
                                to: "/",
                                name: strings.conferenceTexts.showroomTitle + " - " + resp.content.name
                            }
                        ])
                    })
                    .catch((e: { message: React.SetStateAction<string> }) => {
                        // TODO Error when organization can't be fetched
                        setBreadcrumb([
                            {
                                to: "/",
                                name:
                                    strings.conferenceTexts.showroomTitle +
                                    " - " +
                                    strings.conferenceTexts.showroomUnknownCompanyText
                            }
                        ])
                    })
            } else if (meetingKind === "greenroom") {
                setBreadcrumb([
                    {
                        to: "/", // TODO
                        name: strings.conferenceTexts.masterclassTitle
                    },
                    {
                        to: "/", // TODO
                        name: strings.conferenceTexts.greenRoomTitle
                    }
                ])
            } else if (meetingKind === "roundtable") {
                setBreadcrumb([
                    {
                        to: "/", // TODO
                        name: strings.conferenceTexts.roundtableTitle
                    }
                ])
            } else if (meetingKind === "conferenceroom") {
                setBreadcrumb([
                    {
                        to: "/", // TODO
                        name: strings.conferenceTexts.conferenceRoomTitle
                    }
                ])
            } else if (meetingKind === "breakout") {
                const params = {
                    id: roomName.substring(3),
                    rootCategories: branding.eventDateDetailPageContent.rootCategories as any,
                    showCategoryHierarchy: branding.eventDateDetailPageContent.showCategoryHierarchy || (false as any)
                }
                loadEventDateDetails(params).then((data) => {
                    setBreadcrumb([
                        {
                            to: "/",
                            name: strings.conferenceTexts.breakoutTitle
                        },
                        {
                            to: buildDetailLink(data.content.id, data.content.name, "eventdate"),
                            name: data.content.name
                        }
                    ])
                })
            }

            ;(async () => {
                // Same room and still connected? Just bind the audio
                if (meetingStatusCode !== MeetingStatusCode.Succeeded || roomName !== chime.getExternalMeetingId()) {
                    await devices.ensureDevices(
                        checkIfLocalStorageItemExists("audioinputdevices") &&
                            checkIfLocalStorageItemExists("audiooutputdevices", isFirefox || isSafari || false) &&
                            checkIfLocalStorageItemExists("videoinputdevices"),
                        true
                    )

                    chime.createRoom(
                        roomName,
                        devices.currentAudioInputDevice(),
                        devices.currentAudioOutputDevice(),
                        devices.currentVideoInputDevice()
                    )
                }
                accessPresenceState.updateMyPresence(EventType.EVENT_BEGIN)
            })()
            return () => abortController.abort()
        },
        // eslint-disable-next-line
        [roomName]
    )

    useEffect(() => {
        if (
            meetingKind !== "virtualCafe" &&
            meetingKind !== "showroom" &&
            meetingKind !== "greenroom" &&
            meetingKind !== "roundtable" &&
            meetingKind !== "breakout" &&
            meetingKind !== "conferenceroom"
        ) {
            let name = strings.conferenceTexts.callWith
            let first = true
            for (const key in roster) {
                if (loggedInState.user()?.profileId === roster[key]?.id) continue
                const contactName = roster[key]?.name
                if (!contactName) continue
                if (!first) name += ", "
                name += contactName
                first = false
            }
            setBreadcrumb([
                {
                    to: "/",
                    name: name
                }
            ])
        }
        // eslint-disable-next-line
    }, [rosterChangedValue, meetingKind, lang])

    /**********************************************************************************************
     * [START] Effects for managing the Chat window
     **********************************************************************************************/

    const conversationId = roomName.substr(3)
    useEffect(() => {
        if (
            meetingKind === "showroom" ||
            meetingKind === "virtualCafe" ||
            meetingKind === "greenroom" ||
            meetingKind === "roundtable" ||
            meetingKind === "breakout" ||
            meetingKind === "conferenceroom"
        )
            return
        const onCreateUserConversationHandle = (
            API.graphql(
                graphqlOperation(onUserConversationCreatedByConversationLight, { conversationId: conversationId })
            ) as Observable<any>
        ).subscribe({
            next: (resp: any) => {
                const data = resp.value.data as OnUserConversationCreatedByConversationLightSubscription
                const user = data.onUserConversationCreatedByConversation?.user
                if (user) {
                    const newParticipant = { id: user.id, name: user.name ?? "", pictureUrl: user.pictureUrl ?? undefined }
                    setConversationParticipants((participants) =>
                        participants.find((op) => op.id === user.id) ? participants : participants.concat([newParticipant])
                    )
                }
            }
        })
        return () => onCreateUserConversationHandle.unsubscribe()
    }, [conversationId, meetingKind])

    useEffect(() => {
        if (
            meetingKind === "showroom" ||
            meetingKind === "virtualCafe" ||
            meetingKind === "greenroom" ||
            meetingKind === "roundtable" ||
            meetingKind === "breakout" ||
            meetingKind === "conferenceroom"
        )
            return
        const onDeleteUserConversationHandle = (
            API.graphql(
                graphqlOperation(onUserConversationDeletedByConversationLight, { conversationId: conversationId })
            ) as Observable<any>
        ).subscribe({
            next: (resp: any) => {
                const data = resp.value.data as OnUserConversationDeletedByConversationLightSubscription
                const userId = data.onUserConversationDeletedByConversation?.userId
                if (userId) {
                    setConversationParticipants((participants) => participants.filter((p) => p.id !== userId))
                }
            }
        })
        return () => onDeleteUserConversationHandle.unsubscribe()
    }, [conversationId, meetingKind])

    useEffect(() => {
        switch (meetingKind) {
            case "calenderEntry":
                setChatType(ChatType.CALENDARENTRY)
                break
            case "call":
                ;(async () => {
                    const participants = await getConversationParticipants(conversationId, 10)
                    if (participants) {
                        setConversationParticipants(participants)
                        if (participants.length === 2) {
                            setChatType(ChatType.CALL_PRIVATE)
                        } else if (participants.length > 2) {
                            setChatType(ChatType.CALL_GROUP)
                        }
                    } else {
                        // conversation doesn't exist or I'm the only participant (should not occur)
                        setChatType(ChatType.CALL_UNKNOWN)
                    }
                })()
                break
            default:
                setChatType(undefined)
        }
    }, [conversationId, meetingKind])

    useEffect(() => {
        if (chatType === ChatType.CALL_PRIVATE && conversationParticipants.length > 2) {
            setChatType(ChatType.CALL_GROUP)
        }
    }, [conversationParticipants.length]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsExistingMeeting(meetingStatusCode === MeetingStatusCode.Succeeded && roomName === chime.getExternalMeetingId())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const forceShowChat = !isExistingMeeting || (lastChatType === ChatType.CALL_PRIVATE && chatType === ChatType.CALL_GROUP)
        setLastChatType(chatType)
        if (!forceShowChat) {
            return
        }

        ;(async () => {
            switch (chatType) {
                case ChatType.CALENDARENTRY:
                    appState.setShowChatsTab(
                        ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, roomName.substr(3))
                    )
                    break

                case ChatType.CALL_PRIVATE:
                    if (loggedInState.isLoggedIn) {
                        const opponentIds = conversationParticipants.map((p) => p.id).filter((id) => id !== profileId)
                        if (opponentIds.length === 1 && opponentIds[0]) {
                            appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(opponentIds[0]))
                        } else {
                            // Should not occur
                        }
                    }
                    break

                case ChatType.CALL_GROUP:
                    if (loggedInState.isLoggedIn && profileId) {
                        const { conversationExists, userConversationExists } = await checkConversationExists(conversationId, [
                            profileId
                        ])
                        if (conversationExists && userConversationExists[0]) {
                            appState.setShowChatsTab(
                                ChatConversationParam.conversationByConversationId(ConversationType.CALL, conversationId)
                            )
                        } else {
                            // user somehow managed to bypass backend auth check
                            // TODO Error handling
                        }
                    }
                    break
            }
        })()
    }, [chatType]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isPostEventPhase) {
        return (
            <div style={{ backgroundColor: branding.mainInfoColor, width: "100%", height: "100vh" }}>
                <Message>{strings.conferenceTexts.postEventPhaseMessage}</Message>
            </div>
        )
    }

    /**********************************************************************************************
     * [END] Effects for managing the Chat window
     **********************************************************************************************/

    let content: JSX.Element = <div />
    switch (meetingStatusCode) {
        case MeetingStatusCode.Loading:
            content = <CenteredLoader />
            break
        case MeetingStatusCode.Failed:
            if (meetingKind === "greenroom") {
                content = <Message>{chime.getMeetingStatus().errorMessage}</Message>
            } else if (meetingKind === "conferenceroom") {
                content = <Message>{chime.getMeetingStatus().jsonErrorMessage}</Message>
            } else if (meetingKind === "breakout") {
                content = <Message>{chime.getMeetingStatus().jsonErrorMessage}</Message>
                history.push("/program")
            } else {
                content = (
                    <Message>
                        {strings.conferenceTexts.genericError}
                        <br /> {chime.getMeetingStatus().errorMessage}
                    </Message>
                )
            }
            break
        case MeetingStatusCode.Full:
            content = <Message>{strings.conferenceTexts.maxAttendees}</Message>
            break
        case MeetingStatusCode.Disconnected:
            content = <Message>{strings.conferenceTexts.disconnected}</Message>
            break
        case MeetingStatusCode.TimeUp:
            content = <Message>{strings.conferenceTexts.timeUp}</Message>
            break
        case MeetingStatusCode.Kicked:
            content = (
                <Message>
                    {replaceReasonPlaceholderText(
                        strings.conferenceTexts.statusKicked,
                        chime.getMeetingStatus().errorMessage || "",
                        strings.conferenceTexts.statusKickedDefault
                    )}
                </Message>
            )
            break
        case MeetingStatusCode.Banned:
            content = (
                <Message>
                    {replaceReasonPlaceholderText(
                        strings.conferenceTexts.statusBanned,
                        chime.getMeetingStatus().errorMessage || "",
                        strings.conferenceTexts.statusKickedDefault
                    )}
                </Message>
            )
            break
        case MeetingStatusCode.GreenroomLive:
            content = <Message style={{ whiteSpace: "pre-wrap" }}>{strings.conferenceTexts.statusLive}</Message>
            break
        case MeetingStatusCode.Succeeded:
            content = (
                <RoomContainer>
                    <VideoAndControl guestBannerHeight={guestBannerHeight}>
                        <ConferenceContainer guestBannerHeight={guestBannerHeight}>
                            <BreadcrumbRoot>
                                <Breadcrumb breadcrumb={breadcrumb} noTracking={true} />
                            </BreadcrumbRoot>
                            <ContentVideo guestBannerHeight={guestBannerHeight + 50} />
                            <RemoteVideoGroup guestBannerHeight={guestBannerHeight + 50} />
                            <StyledControls redirectToRoom isChatGroup={chatType === ChatType.CALL_GROUP} />
                        </ConferenceContainer>
                    </VideoAndControl>
                    <SidebarRoster>
                        <Roster conversationParticipants={conversationParticipants} guestBannerHeight={guestBannerHeight} />
                    </SidebarRoster>
                </RoomContainer>
            )
            break
        default:
            content = <Message>{strings.conferenceTexts.genericError}</Message>
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar
                textColor="#fff"
                backgroundColor={branding.mainInfoColor ?? "black"}
                background={branding.topBar.background}
            />
            <ConferenceRoomRoot guestBannerHeight={guestBannerHeight}>
                {content}
                {chime.isMutedByMod() && (
                    <ModMutedBar isRosterOpen={appState.isRosterOpen}>
                        {strings.conferenceTexts.mutedByMod}
                        <StyledButton onClick={() => chime.realtimeUnmuteLocalAudio()}>
                            {strings.conferenceTexts.unmute}
                        </StyledButton>
                        <StyledButton onClick={() => chime.setMutedByMod(false)}>
                            {strings.conferenceTexts.closeMutedBy}
                        </StyledButton>
                    </ModMutedBar>
                )}
            </ConferenceRoomRoot>
        </>
    )
}
