import { useEffect, useState } from "react"
import * as React from "react"
import { NavLink, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import { doConnectAction, setConnectIsViewed } from "../backendServices/BackendServices"
import { ModalType } from "../backendServices/Types"
import branding from "../branding/branding"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../globalStates/AppState"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { AvatarWithPresenceState } from "../ui/AvatarWithPresenceState"
import BadgesContainer from "../ui/BadgesContainer"
import ContextMenu, { HoverButton } from "../ui/ContextMenu"
import { IconCheckmark, IconIgnore } from "../ui/Icons"
import { Action, CommunicationModals, createActions } from "./CommunicationOptions"
import { useContactState } from "./ContactState"
import { format } from "date-fns"
import moment from "moment"

export enum ContactEntryType {
    RELEVANT,
    REQUEST,
    CONTACT,
    BLOCKED,
    ROSTER,
    PARTICIPANTS
}

const ContactEntryRoot = styled.div`
    background-color: transparent;
    overflow: hidden;
`

const HoverRoot = styled(NavLink)`
    width: 100%;

    &:hover {
        background-color: rgba(240, 240, 240, 1);
        text-decoration: none;
    }
    &.disabled:hover {
        background-color: rgba(0, 0, 0, 0.25);
        text-decoration: none;
    }
    &:active,
    &:focus {
        text-decoration: none;
    }
`

const ContactEntryContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 10px;
    background-color: transparent;
    color: ${branding.mainInfoColor ?? "#000"};
    width: 100%;

    &:hover {
        background-color: rgba(240, 240, 240, 1);
    }

    &.disabled {
        opacity: 25%;
    }
`

const PersonName = styled.div`
    margin-top: 13px;
    margin-left: 2px;
    font-family: ${branding.contactEntry.personNameFont ?? branding.font1};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: ${branding.contactEntry.contactEntryLetterSpacing};
`

const PersonPosition = styled.div`
    margin-left: 2px;
    margin-top: 3px;
    font-family: ${branding.contactEntry.personPositionFont ?? branding.font1};
    font-weight: ${branding.contactEntry.personPositionFontWeight};
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
`

const PersonDataRoot = styled.div<{ color?: string }>`
    display: flex;
    flex-grow: 1;
    grid-template-columns: 30% 70%;
    flex-direction: row;
    margin-bottom: 8px;
    color: ${(props) => props.color};
`

const UnreadMarker = styled.div`
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: ${branding.conversationEntry.unreadMarkerBgColor ?? "#0000FF"};
    margin-top: 22px;
    margin-right: 5px;
    padding: 5px;
`

interface ContactEntryProps {
    contact: any
    isIncomingRequest?: boolean
    hideMenu?: boolean
    withoutPresence?: boolean
    setReload?: () => void
    additionalActions?: Action[]
    disabled?: boolean
    className?: string // needed for outside styled styling
    entryType: ContactEntryType
    useOnlyLastNameForRoute?: boolean
}

const ContactEntry: React.FunctionComponent<ContactEntryProps> = (props) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const person = props.contact
    const [modalType, setModalType] = useState<ModalType>("none")
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const strings = useLanguageState().getStrings()
    const userLink = useLoggedInState()
    const favoriteState = useFavoriteState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const contactState = useContactState()
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    const [connectionStatus, setConnectionStatus] = useState("")

    const [userTypeStatus, setUserTypeStatus] = useState("")

    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)

    const [newMessage, setNewMessage] = useState(false)

    function checkIfBookmarked(): boolean {
        return (
            favoriteState.is("person", person.id) ||
            favoriteState.is("sotuser", person.id) ||
            favoriteState.is("person", person.person)
        )
    }

    useEffect(() => {
        if (
            props.entryType === ContactEntryType.CONTACT &&
            person.myConnectionStatus === "CONNECTED" &&
            !person.myConnectionViewed
        ) {
            setNewMessage(true)
        }
    }, [newMessage, person, props.entryType])

    useEffect(() => {
        setUserTypeStatus(contactState.getUserType(person.id))
        setIsBookmarked(checkIfBookmarked())

        setConnectionStatus(contactState.getConnectionStatus(person.id))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(person.id))
        setUserTypeStatus(contactState.getUserType(person.id))
        setIsBookmarked(checkIfBookmarked())
        // eslint-disable-next-line
    }, [person, contactState, favoriteState])

    //const connectionStatus = contactState.getConnectionStatus(person.id)
    //const userTypeStatus = contactState.getUserType(person.id)
    //const isBookmarked = favoriteState.is("person", sotUserId)

    const personName = [person.firstName, person.lastName].filter(Boolean).join(" ")

    const personPosition: string = [person.position, person.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")

    const personPositionDe: string = [person.positionDe ?? person.position, person.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")

    const connectionCreated: string = person.created ?? ""

    const hourFormat = lang === "en" ? "hh:mm A" : "HH:mm"

    const menuCallback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void = ({
        bookmarkChanged,
        modalType
    }) => {
        if (bookmarkChanged) {
            if (props.setReload) {
                props.setReload()
            }
        }
        if (modalType) {
            setModalType(modalType)
        }
    }

    function onClick() {
        if (userLink.user()?.profileId && newMessage) {
            setConnectIsViewed({ profileId: userLink.user()!.profileId, viewedProfiles: [person.id] }).then(() =>
                setNewMessage(false)
            )
            person.myConnectionViewed = true
            setNewMessage(!newMessage)
        }
    }

    const avatar = props.withoutPresence ? (
        <AvatarWithDefault size={50} /*was 56 */ alt={personName} src={person.logoUrl} backgroundSize="cover" />
    ) : (
        <AvatarWithPresenceState
            avatarSize={56} /*was 56 */
            badgeSize={15}
            showAvatarBadge={true}
            badgeRight={0.1} /*was 0.1 */
            badgeTop={37} /*was 37 */
            userId={person.id}
            userType={person.type}
            content={{ pictureUrl: person.logoUrl, alt: personName }}
        ></AvatarWithPresenceState>
    )
    const otherBadges: string[] = []
    if (person.type === "press") {
        otherBadges.push(strings.press)
    }

    if (person.type === "staff" && branding.myProfilePageContent.showExhibitorBadge) {
        otherBadges.push(strings.globalSearchResultPage.exhibitorEntityTitle)
    }

    return (
        <ContactEntryRoot className={props.className}>
            <HoverRoot
                to={buildDetailLink(
                    person.id,
                    (props.useOnlyLastNameForRoute ? "" : person.firstName) + "_" + person.lastName,
                    "user"
                )}
                className={props.disabled ? "disabled" : ""}
            >
                {props.isIncomingRequest && person.created && (
                    <PersonPosition
                        style={{
                            fontSize: branding.contactEntry.personPositionFontSize,
                            color: branding.mainInfoColor ?? "#000",
                            paddingTop: "15px",
                            paddingBottom: "10px",
                            paddingLeft: "16px"
                        }}
                    >
                        {format(moment(connectionCreated).toDate(), strings.eventTiming.eventDaysFormatPatternNewsPage)}{" "}
                        {moment(connectionCreated).format(hourFormat)}
                    </PersonPosition>
                )}
                <ContactEntryContainer className={props.disabled ? "disabled" : ""}>
                    <PersonDataRoot
                        color={isMeetingPage ? branding.secondaryColor ?? "white" : branding.mainInfoColor ?? "black"}
                        onClick={onClick}
                    >
                        <div style={{ marginTop: "10px", flexGrow: 0, display: "flex" }}>
                            {newMessage === true && <UnreadMarker />}
                            {avatar}
                        </div>
                        <div className="person-details" style={{ flexGrow: 1, paddingLeft: "10px" }}>
                            <PersonName style={{ fontSize: branding.contactEntry.personNameFontSize }}>{personName}</PersonName>
                            <PersonPosition style={{ fontSize: branding.contactEntry.personPositionFontSize }}>
                                {lang === "en" ? personPosition : personPositionDe}
                            </PersonPosition>

                            <BadgesContainer
                                topics={person.topics}
                                otherBadges={otherBadges}
                                categoryBadges={person.categoryBadges}
                            />
                        </div>
                    </PersonDataRoot>
                    <div style={{ alignSelf: "center" }}>
                        {props.hideMenu || props.isIncomingRequest ? null : (
                            <HoverButton onClick={() => setCollapsed(!collapsed)} selected={!collapsed} />
                        )}
                    </div>
                </ContactEntryContainer>
            </HoverRoot>
            <ContextMenu
                collapsed={collapsed}
                items={() =>
                    createActions(
                        userLink.user(),
                        person,
                        favoriteState,
                        contactState,
                        appState,
                        meeting,
                        chime,
                        strings,
                        isBookmarked,
                        connectionStatus,
                        userTypeStatus,
                        menuCallback,
                        props.additionalActions
                    )
                }
            />
            <CommunicationModals
                show={modalType}
                contact={person}
                removePosition={true}
                onWithdrawRequest={() => {
                    if (props.setReload) {
                        props.setReload()
                    }
                }}
                onHide={() => setModalType("none")}
            />
            {props.isIncomingRequest && <IncomingRequestMenu {...props} />}

            <Divider />
        </ContactEntryRoot>
    )
}

export const Divider = styled.div`
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    margin-left: 15px;
`

export { ContactEntry }

const RequestMessage = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 12px;
    color: black;
    width: 100%;
    padding: 10px 10px 0 10px;
    font-style: italic;

    &.longStringWithoutSpaces {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
`

const IncomingRequestMenu: React.FunctionComponent<ContactEntryProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const [isRequestSending, setIsRequestSending] = useState<boolean>(false)

    function isLongStringWithoutSpaces(): boolean {
        const text = props.contact.requestMessage

        if (text && text.length >= 47 && !text.slice(0, 47).includes(" ")) {
            return true
        }

        return false
    }

    function connectActionHandler(action: string, message: string | null) {
        setIsRequestSending(true)
        doConnectAction({
            profileId: loggedInUserId as string,
            targetProfileId: props.contact.id,
            message: message,
            action: action
        })
            .then((response: any) => {
                if (response.content) {
                    if (props.setReload) {
                        props.setReload()
                    }
                }
                setIsRequestSending(false)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsRequestSending(false)
            })
    }

    return (
        <>
            <RequestMessage className={isLongStringWithoutSpaces() ? "longStringWithoutSpaces" : ""}>
                {props.contact.requestMessage ? props.contact.requestMessage : null}
            </RequestMessage>
            <ContextMenu
                collapsed={false}
                items={() => [
                    {
                        title: strings.communicationArea.requestAcceptTitle,
                        icon: IconCheckmark({ fill: branding.sideIconBar.sideIconColorDark }),
                        disabled: isRequestSending,
                        onClick: () => connectActionHandler("accept", null)
                    },
                    {
                        title: strings.communicationArea.requestIgnoreTitle,
                        icon: IconIgnore({ fill: branding.sideIconBar.sideIconColorDark }),
                        disabled: isRequestSending,
                        onClick: () => connectActionHandler("ignore", null)
                    }
                ]}
            />
        </>
    )
}
